import { IonContent, IonPage, IonButton, IonLoading, IonSegment, IonSegmentButton, IonLabel } from "@ionic/react";
import "./manage-store.css";
import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { fetchFavoriteStores } from "../../redux/stores/stores.actions";
import { setAppLoading } from "../../redux/global/global.actions";
import { StoreTabs } from "../../components/store-tabs/store-tabs";
import {
  fetchAllStoresNoFilter,
  fetchPrefectures,
  removeFromFavStores,
} from "../../redux/retailers/retailers.actions";
import { StoreSelectionFlow } from "../store-selection-flow/store-selection-flow";
import { StoreSelectionSearch } from "../store-selection-search/store-selection-search";
import { StoreTabFavorites } from "../store-tab-favorites/store-tab-favorites";
import { TopBanner } from "../../components/top-banner/top-banner";
import { ScrollpageWithHeader } from "../../common/scrollpage-with-header/scrollpage-with-header";
const CURRENT_ENV = process.env.REACT_APP_ENV as string;

const mapStateToProps = (state: any) => {
  return {
    favoriteStores: state.stores.favoriteStores,
    selectedStore: state.stores.selectedStore,
    favoriteStoresLoaded: state.stores.favoriteStoresLoaded,
    storesLoaded: state.stores.storesLoaded,
    allStoresLoading: state.retailers.allStoresLoading,
    loading: state.retailers.loading,
    isAppLoading: state.global.isAppLoading,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    fetchFavoriteStores: () => dispatch(fetchFavoriteStores()),

    removeFromFavStores: (storeId: any) =>
      dispatch(removeFromFavStores(storeId)),
    setAppLoading: (data: any) => dispatch(setAppLoading(data)),
    fetchAllStoresNoFilter: (retailer: any) =>
      dispatch(fetchAllStoresNoFilter(retailer)),
    fetchPrefectures: (retailer: any) => dispatch(fetchPrefectures(retailer)),
  };
};
interface Props {
  selectedStore: any;
  favoriteStores: any[];
  storesLoaded: boolean;
  favoriteStoresLoaded: boolean;
  allStoresLoading: boolean;
  isAppLoading: boolean;
  loading: boolean;
  fetchFavoriteStores: Function;
  setAppLoading: Function;
  fetchPrefectures: Function;
  fetchAllStoresNoFilter: Function;
  removeFromFavStores: Function;
}

const filters = [
  { id: 1, name: "Favorite stores" },
  { id: 2, name: "Store area selection" },
  { id: 3, name: "Search from store list" },
];
export const ManageStoreComponent = (props: Props) => {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  let from_terms = localStorage.getItem('from-terms');
  let selected_tab = from_terms ? filters[1] : filters[0]
  // localStorage.removeItem('from-terms')
  const [selectedCategory, setSelectedCategory] = useState(selected_tab);
  const { t } = useTranslation();

  useEffect(() => {
    // setTimeout(()=>{
    //   props.fetchPrefectures(CURRENT_ENV === 'prod' ? { id: 19 } : { id: 59 });
    //   props.fetchAllStoresNoFilter(CURRENT_ENV === 'prod' ? { id: 19 } : { id: 59 });
    //   props.fetchFavoriteStores();
    // }, 100)
    props.fetchPrefectures(CURRENT_ENV === 'prod' ? { id: 19 } : { id: 59 });
    props.fetchAllStoresNoFilter(CURRENT_ENV === 'prod' ? { id: 19 } : { id: 59 });
    props.fetchFavoriteStores();
    // props.setAppLoading(true);
  }, []);

  useEffect(() => { }, [selectedCategory]);

  return (
    // <IonPage className="pt-16 bg-okuwa_bg overflow-auto">
    <ScrollpageWithHeader id="stores" title={"Stores"}>
      <IonContent>
        <TopBanner />
        <IonLoading
          cssClass="my-custom-class"
          isOpen={props.allStoresLoading && !props.favoriteStoresLoaded && props.loading}
          onDidDismiss={() => {}}
          message={"お待ちください..."}
          duration={5000}
        />
        <div className="sticky top-0 z-10">
          {/* <StoreTabs filters={filters} onTabChanged={onTabChanged} /> */}
          <div className="bg-white">
            <IonSegment
              mode="md"
              scrollable
              value={selectedCategory ? selectedCategory.name : null}
            >
              {filters.map((category) => {
                return (
                  <IonSegmentButton
                    key={category.id}
                    className={
                      selectedCategory === category ? "" : "border-b-2 border-gray-100 text-left text-xs dark:text-gray-200"
                    }
                    value={category.name}
                    onClick={() => {
                      setSelectedCategory(category);
                    }}
                  >
                    <IonLabel className="-m-2 dark:text-black">{t(category.name)}</IonLabel>
                  </IonSegmentButton>
                );
              })}
            </IonSegment>
          </div>
        </div>
        {
          {
            1: <StoreTabFavorites filters={filters} setSelectedCategory={setSelectedCategory} />,
            2: <StoreSelectionFlow filters={filters} setSelectedCategory={setSelectedCategory} />,
            3: <StoreSelectionSearch filters={filters} setSelectedCategory={setSelectedCategory} />,
          }[selectedCategory.id]
        }
      </IonContent>
      <div className="mt-1 border-t-2 border-slate-300">
        <IonButton
          color="white"
          expand="full"
          routerLink="/home"
        >
          <span className="font-bold text-okuwa_blue" >クーポン画面へ</span>
        </IonButton>
      </div>
    </ScrollpageWithHeader>
    // </IonPage>
  );
};

export const ManageStore = connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageStoreComponent);