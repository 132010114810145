import { t } from "i18next";
import { useTranslation } from "react-i18next";
import step0 from "./step0.png";
import step1 from "./step1.png";
import step2 from "./step2.png";
import step3 from "./step3.png";

const steps = [
  {
    name: "step_1",
    image: step1,
    status: "complete",
  },
  {
    name: "step_2",
    image: step2,
    status: "complete",
  },
  {
    name: "step_3",
    image: step3,
    status: "complete",
  },
];

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

interface Props {
  stepsref?: any;
}
export const Steps = (props:Props) => {
  const { t } = useTranslation();
  return (
    <nav aria-label="Progress" className="px-4 py-8 pt-8 bg-okuwa_bg dark:text-black" ref={props.stepsref}>
      <div className="text-xl">{t("how_to_use_coupon")}</div>
      <div className="text-sm pt-2">{t("4_simple_steps")}</div>
      <ol role="list" className="overflow-hidden py-12">
        {steps.map((step, stepIdx) => (
          <li
            key={step.name}
            className={classNames(
              stepIdx !== steps.length - 1 ? "pb-10" : "",
              "relative"
            )}
          >
            <>
              {stepIdx !== steps.length - 1 ? (
                <div
                  className="-ml-px absolute left-4 top-4 w-0.5 h-full bg-gray-700"
                  aria-hidden="true"
                />
              ) : null}
              <a className="relative flex items-start group">
                <span className="h-14 flex items-center">
                  <span className="relative z-10 w-8 h-8 flex items-center justify-center bg-gray-900 rounded-full">
                    <div className="text-white font-bold">{stepIdx + 1}</div>
                  </span>
                </span>
                <span className="ml-4 min-w-0 flex">
                  <div className="flex">
                    <img src={step.image} className="h-12" />
                    {step.name === "step_3" &&
                        <div className="text-sm tracking-wide pl-4">
                          {t("step_3_1")}<br/>
                          ※バスケットに追加されたクーポンは、クーポン一覧から一時的に表示が消えますが、
                          クーポンご利用後すぐにクーポン一覧に表示されます。再度ご利用の場合は、バスケットに追加してご利用ください。
                          ※クーポンによっては再使用可能回数に制限がある場合がございます。
                          <br/><br/>
                          登録（タッチ）したクーポン有効期限の確認方法<br/>
                          ①画面上部のバスケット（画像）を選択<br/>
                          ②登録済みのクーポン画面下部に有効期限が表示<br/>
                          ※商品によって有効期限が異なりますので登録（タッチ）後
                          は有効期限をお確かめの上ご利用願います。<br/>
                        </div>
                    }
                    {step.name != "step_3" &&
                        <span className="text-sm tracking-wide pl-4">
                        {t(step.name)}
                      </span>
                    }
                  </div>
                </span>
              </a>
            </>
          </li>
        ))}
      </ol>
    </nav>
  );
};
