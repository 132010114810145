import { IonHeader } from "@ionic/react";
import logo from "./logo-okuwa.jpg";
import { Switch, useLocation } from 'react-router-dom'
import { useHistory } from "react-router-dom";

import {
  // CreditCardIcon,
  // ShoppingBagIcon,
  // ShoppingCartIcon,
  // UserCircleIcon,
} from "@heroicons/react/solid";

import coupon from "./coupon.png";
import cart from "./cart.png";
import exclusion from "./exclusion.png";
import user from "./user.png";
import info from "./info.png";
import { useEffect, useState } from "react";
import GTMService from "../../services/gtm.service";
import { connect } from "react-redux";
import { setAppLoading } from "../../redux/global/global.actions";

const buttons = [
  {
    id: 1,
    image: coupon,
    isSelected: true,
    link: '/home',
    text: 'クーポン'
  },
  {
    id: 2,
    image: cart,
    isSelected: false,
    link: '/cart',
    text: 'バスケット'
  },
  {
    id: 3,
    image: user,
    isSelected: false,
    link: '/points',
    text: 'マイポイント'
  },
  {
    id: 4,
    image: info,
    isSelected: false,
    link: '/info',
    text: '情報'
  },
];

export function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

interface Props {
  state?: any;
  isAppLoading?: boolean;
  setAppLoading?: Function;
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    setAppLoading: (data: any) => dispatch(setAppLoading(data)),
  };
};
const mapStateToProps = (state: any) => {
  return {
    state: state,
    isAppLoading: state.global.isAppLoading,
  };
};

export const HeaderComponent = (props: Props) => {
  const history = useHistory();
  let location = useLocation()
  const [showHeader, setShowHeader] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState(buttons[0].link)
  useEffect(
    () => {
      // console.log('Location changed', window.location.pathname);
      setSelectedTab(window.location.pathname)
      if (window.location.pathname === '/stores' || window.location.pathname === '/product-details' || window.location.pathname === '/terms' || window.location.pathname === '/terms2' || window.location.pathname === '/invalid-card-number' || window.location.pathname === '/' || window.location.pathname === '/error') {
        setShowHeader(false)
      } else {
        setShowHeader(true)
      };
    },
    [location]
  )
  if (showHeader) {
    return (
      <IonHeader mode="ios" className="px-3 py-1 bg-okuwa_bg flex justify-between items-center">
        {/*<Link*/}
        {/*  className=""*/}
        {/*  to="/pages"*/}
        {/*>*/}
        <img className="h-8 my-1 ml-1" src={logo} />
        {/*</Link>*/}
        <div className="flex items-center">
          {buttons.map((button) => (
            <div key={button.id}><div
              onClick={() => {
                buttons.find(function (checkButton) {
                  if (checkButton.id != button.id) {
                    checkButton.isSelected = false;
                  }
                });
                GTMService.triggerGTM({
                  eventName: 'NAVIGATE',
                  url: button.link,
                  data: { ...props.state },
                });
                button.isSelected = true;
                setSelectedTab(button.link);
                history.push(button.link);
              }}
              className={classNames(
                "ml-2 h-10 w-10 rounded-full flex items-center justify-center relative",
                button.link === selectedTab
                  ? "bg-white"
                  : ""
              )}
            >
              <img src={button.image} className="h-6 w-6" />
              {button.id === 3 && <img className="absolute w-3 h-3 top-1.5 right-1 bg-white rounded-full" src={exclusion} />}
            </div>
              <div
                className={classNames(
                  "h-5 w-14 flex items-center justify-center relative dark:text-black",
                  button.link === selectedTab
                    ? "bg-white text-center font-bold ..."
                    : "text-center font-bold ..."
                )}
                style={{ fontSize: 9 }}>
                {button.text}
              </div></div>
          ))}
        </div>
      </IonHeader>
    );
  } else {
    return null
  }
};

export const Header = connect(
  mapStateToProps,
  mapDispatchToProps
)(HeaderComponent);
