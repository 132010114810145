import { connect } from "react-redux";
import {
  setWalletEndDate,
  setWalletStartDate,
} from "../../../redux/wallet/wallet.actions";
import { DateDropdown } from "../dropdown/dropdown";
import { ArrowNarrowRightIcon } from "@heroicons/react/solid";

const mapStateToProps = (state: any) => {
  return {
    startDate: state.wallet.startDate,
    endDate: state.wallet.endDate,
    wallet: state.wallet,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setWalletStartDate: (data: any) => dispatch(setWalletStartDate(data)),
    setWalletEndDate: (data: any) => dispatch(setWalletEndDate(data)),
  };
};
interface Props {
  startDate: string;
  endDate: string;
  setWalletStartDate: Function;
  setWalletEndDate: Function;
  refetchWallet: Function;
}

const DateFilterComponent = (props: Props) => {
  // console.log(props.startDate, new Date(props.startDate).toISOString());
  // const [showStartModal, setShowStartModal] = useState(false);
  // const [showEndModal, setShowEndModal] = useState(false);
  return (
    <>
      <div className="relative dark:text-black">
        <div className="absolute inset-0 flex flex-col -z-10">
          <div className="flex-1 bg-gray-100"></div>
          <div className="flex-1 dark:bg-gray-100"></div>
        </div>
        <div className="px-4 py-6 mx-4 bg-white rounded-md shadow-custom">
          <div className="text-xl font-semibold">獲得ポイント期間</div>
          <div className="pt-4 text-sm">期間を選択してください</div>
          <div className="flex items-center mt-4">
            <DateDropdown
              // startDate={props.startDate}
              // endDate={props.endDate}
              setWalletStartDate={props.setWalletStartDate}
              setWalletEndDate={props.setWalletEndDate}
              refetchWallet={props.refetchWallet}
              mode="START"
            />
            <ArrowNarrowRightIcon className="h-5 w-5 mx-2 text-gray-400" />
            <DateDropdown
              // startDate={props.startDate}
              // endDate={props.endDate}
              setWalletStartDate={props.setWalletStartDate}
              setWalletEndDate={props.setWalletEndDate}
              refetchWallet={props.refetchWallet}
              mode="END"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export const DateFilter = connect(
  mapStateToProps,
  mapDispatchToProps
)(DateFilterComponent);
