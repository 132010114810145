import { IonContent, IonSlide, IonSlides } from "@ionic/react";
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";

import Onb1 from "./images/Onb1.png";
import Onb2 from "./images/Onb2.png";
import Onb3 from "./images/Onb3.png";
import Onb4 from "./images/Onb4.png";
import AuthService from "../../services/auth.service";
import MembersService from "../../services/members.service";
import DataService from "../../services/data.service";
import { useLocation } from "react-router-dom";
import { setAppLoading } from "../../redux/global/global.actions";
import { connect } from "react-redux";
import { classNames } from "../header/header";
import CryptoJS from "crypto-js";
import { setSelectedStore, setFavoriteStores } from "../../redux/stores/stores.actions";
import { useTranslation } from "react-i18next";
import GTMService from "../../services/gtm.service";
import {AppSettings} from "../../AppSettings";

const CURRENT_ENV = process.env.REACT_APP_ENV as string;
// const CURRENT_ENV = process.env.REACT_APP_ENV as string;
const SECRET_KEY = process.env.REACT_APP_SECRET_KEY as string;
// const SECRET_KEY = "1qaz2wsx3edc4rfv";
const IV = process.env.REACT_APP_IV as string;
// const IV = "tfnA4sDSVQ53xzmttW8L0A==";

function useQueryParams() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

let code = (function () {
    const iv = CryptoJS.enc.Base64.parse(IV);
    return {
        encryptMessage: function (messageToencrypt = "") {
            var encryptedMessage = CryptoJS.AES.encrypt(
                messageToencrypt,
                CryptoJS.enc.Utf8.parse(SECRET_KEY),
                { iv }
            );
            // console.log(encryptedMessage.toString());
            return encryptedMessage.toString();
        },
        decryptMessage: function (encryptedMessage = "") {
            return CryptoJS.AES.decrypt(
                encryptedMessage,
                CryptoJS.enc.Utf8.parse(SECRET_KEY),
                { iv }
            ).toString(CryptoJS.enc.Utf8);
        },
    };
})();
const decryptMessage = (encryptedMessage: string): Promise<string> => {
    return new Promise((resolve) => {
        const iv = CryptoJS.enc.Base64.parse(IV);
        resolve(
            CryptoJS.AES.decrypt(
                encryptedMessage,
                CryptoJS.enc.Utf8.parse(SECRET_KEY),
                { iv }
            ).toString(CryptoJS.enc.Utf8)
        );
    });
};
const mapDispatchToProps = (dispatch: any) => {
    return {
        setAppLoading: (data: any) => dispatch(setAppLoading(data)),
        setSelectedStore: (data: any) => dispatch(setSelectedStore(data)),
        setFavoriteStores: (data: any) => dispatch(setFavoriteStores(data)),
    };
};
const mapStateToProps = (state: any) => {
    return {
        isAppLoading: state.global.isAppLoading,
    };
};
export const OnboardingComponent = (props: any) => {
    const { t } = useTranslation();
    const slides = [
        {
            image: Onb1,
            text: t("Onboarding_1"),
        },
        {
            image: Onb2,
            text: t("Onboarding_2"),
        },
        {
            image: Onb3,
            text: t("Onboarding_3"),
        },
        {
            image: Onb4,
            text: t("Onboarding_4"),
        },
    ];
    const mySlides = useRef<HTMLIonSlidesElement>(null);
    const [isLastSlide, setLastSlide] = useState(false);
    const [isFirstSlide, setFirstSlide] = useState(true);
    const cardNo = React.useRef("");
    const [password] = useState<string>(AppSettings.CATALINA_PASSWORD);
    const slideOpts = {
        initialSlide: 0,
        speed: 200,
    };
    const queryParams = useQueryParams();
    const history = useHistory();

    useEffect(() => {
        if (CURRENT_ENV === "dev") {
            if (queryParams.get("c")) {
                cardNo.current = code.decryptMessage(
                    code.encryptMessage(queryParams.get("c")!)
                );
            }
            if (
                !cardNo.current ||
                cardNo.current.length !== 16 ||
                !/^\d+$/.test(cardNo.current)
            ) {
                history.push("/invalid-card-number");
            } else {
                handleLogin();
            }
        } else {
            const decodeQueryParam = async () => {
                if (queryParams.get("c")) {
                    cardNo.current = await decryptMessage(
                        queryParams.get("c")!.replace(/ /g, "+")
                    );
                    if (
                        !cardNo.current ||
                        cardNo.current.length !== 16 ||
                        !/^\d+$/.test(cardNo.current)
                    ) {
                        history.push("/invalid-card-number");
                    } else {
                        handleLogin();
                    }
                }
            };
            decodeQueryParam();
        }
    }, []);

    const getFavoriteStoresAndTriggerGTM = () => {
        MembersService.getFavoriteStores().then((favresponse) => {
            const mappedStoreId = DataService.getStoreMapping(queryParams.get('s'));
            let storesList = favresponse.data.map((b: { id: any }) => b.id).join("_");
            let starStore = favresponse.data.filter((f: { id: any; }) => f.id === Number(mappedStoreId))[0];
            if (starStore) {
                props.setFavoriteStores(favresponse.data)
                localStorage.removeItem("fav-store");
                localStorage.setItem('fav-store', JSON.stringify(starStore))
                props.setSelectedStore(starStore);
            }
            GTMService.triggerGTMLogin(storesList, starStore, cardNo)
        });
    }

    const handleLogin = () => {
        props.setAppLoading(true);

        AuthService.login(cardNo.current + AppSettings.CATALINA_DOMAIN, password)
            .then(async (response) => {
                localStorage.setItem("user-data", JSON.stringify(response.data));
                if (
                    queryParams.get("s") !== null &&
                    /^\d+$/.test(queryParams.get("s")!)
                ) {
                    const mappedStoreId = DataService.getStoreMapping(
                        queryParams.get("s")
                    );
                    if (mappedStoreId) {
                        MembersService.addStoreToFavs(mappedStoreId, null).then((response) => {
                            getFavoriteStoresAndTriggerGTM();
                        })
                            .catch(
                                (error) => {
                                    console.log(error);
                                    // if (error.response.data.code[0] === 2813 || error.response.data.code[0] === 2837) {
                                    // console.log("existing store")
                                    getFavoriteStoresAndTriggerGTM();
                                }
                            )

                    }
                }
                return MembersService.getFavoriteStores();
            })
            .then((response) => {
                if (response.data) {
                    history.replace("/home");
                } else {
                    history.push("/2");
                }
            })
            .catch((err) => {
                props.setAppLoading(false);
                if (!err) {
                    // setErrorMessage(err.response.data.error);
                    // setOpenErrorModel(true);
                }
            });
    };
    const getQueryParams = () => {
        const params: any = {
            c: cardNo.current,
        };
        if (queryParams.get("s")) {
            params.s = queryParams.get("s");
        }
        return new URLSearchParams(params);
    };
    if (props.isAppLoading) {
        return (
            <div className="flex justify-center pt-24">
                <div className="loader">
                    <div className="dot"></div>
                    <div className="dot"></div>
                    <div className="dot"></div>
                    <div className="dot"></div>
                    <div className="dot"></div>
                </div>
            </div>
        );
    }
    return (
        <IonContent>
            <div className="absolute inset-0 flex flex-col">
                <div
                    className="p-4 text-right text-sm underline dark:text-black"
                    onClick={() =>
                        history.push({
                            pathname: "/terms",
                            search: getQueryParams().toString(),
                        })
                    }
                >
                    スキップ
                </div>
                <div className="flex flex-1 py-12">
                    <IonSlides
                        className="onboarding-slider"
                        onIonSlideDidChange={() => {
                            setFirstSlide((mySlides.current as any).swiper.isBeginning);
                            setLastSlide((mySlides.current as any).swiper.isEnd);
                        }}
                        ref={mySlides}
                        pager={true}
                        options={slideOpts}
                    >
                        {slides.map((slide) => (
                            <IonSlide>
                                <div className="flex flex-col px-6">
                                    <div className="px-16">
                                        <img className="max-w-xs" src={slide.image} />
                                    </div>
                                    <div className="p-6 text-sm pt-10 dark:text-black">{slide.text}</div>
                                </div>
                            </IonSlide>
                        ))}
                    </IonSlides>
                </div>
                <div className="flex pb-8 justify-center p-3">
                    {
                        <button
                            onClick={() => {
                                (mySlides.current as any).swiper.slidePrev();
                            }}
                            style={{ maxWidth: "35%" }}
                            type="button"
                            className={classNames(
                                "mr-2 flex-1 justify-center items-center inline-flex px-3 py-3 border border-transparent text-xs font-medium rounded-xl shadow-sm text-gray-800 bg-gray-200 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300",
                                isFirstSlide ? "opacity-0 pointer-events-none" : ""
                            )}
                        >
                            戻る
                        </button>
                    }
                    <button
                        onClick={() => {
                            if (isLastSlide) {
                                history.push({
                                    pathname: "/terms",
                                    search: getQueryParams().toString(),
                                });
                            } else {
                                (mySlides.current as any).swiper.slideNext();
                            }
                        }}
                        style={{ maxWidth: "35%" }}
                        type="button"
                        className="ml-2 flex-1 justify-center items-center inline-flex px-3 py-1.5 border border-transparent text-xs font-medium rounded-xl shadow-sm text-white bg-gray-900 hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-700"
                    >
                        {isLastSlide ? "今すぐ始める" : "次へ"}
                    </button>
                </div>
            </div>
        </IonContent>
    );
};
export const Onboarding = connect(
    mapStateToProps,
    mapDispatchToProps
)(OnboardingComponent);
