import { useTranslation } from "react-i18next";

export const TotalSavings = (props: any) => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col items-center p-4 m-4 mt-6 dark:bg-gray-100 dark:m-0">
      <div className="pt-2 pb-4 dark:text-black">{t("Total_points")}</div>
      <div className="pb-1 text-4xl font-bold text-okuwa_blue">{props.sumOfHistoryPoints}
        <span className="text-xl">{t("Points")}</span></div>
      {/* <div className="pb-3 text-2xl font-bold text-okuwa_blue">円相当</div> */}
      <div className="pb-1 text-gray-500">{t("Points_message")}</div>
      {/* <div className="pb-2 text-gray-500">{t("Points_message_2")}</div> */}
    </div>
  );
};
