import { IonButton, IonCheckbox, IonItem, IonLabel } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import AuthService from "../../services/auth.service";
import MembersService from "../../services/members.service";
import { ScrollpageWithHeader } from "../../common/scrollpage-with-header/scrollpage-with-header";
import DataService from "../../services/data.service";
import { setSelectedStore, setFavoriteStores } from "../../redux/stores/stores.actions";
import { connect } from "react-redux";
import GTMService from "../../services/gtm.service";
import { NewUpdatedTerms } from "../info/infoscreens/terms-service";
import Checkbox from "react-custom-checkbox";
import {CheckIcon} from "@heroicons/react/solid";
import {AppSettings} from "../../AppSettings";

const CURRENT_ENV = process.env.REACT_APP_ENV as string;
// const CURRENT_ENV = process.env.REACT_APP_ENV as string;

declare global {
  interface Window {
    dataLayer: Array<Object>;
  }
}
function useQueryParams() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const mapStateToProps = (state: any) => {
  return {
    isAppLoading: state.global.isAppLoading,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setSelectedStore: (data: any) => dispatch(setSelectedStore(data)),
    setFavoriteStores: (data: any) => dispatch(setFavoriteStores(data)),
  };
};

export const TermsPageComponent = (props: any) => {
  const history = useHistory();
  let queryParams = useQueryParams();
  const [checked, setChecked] = useState(false);
  const [email, setEmail] = useState<string>("6346-2387-2387-1125");
  const cardNo = React.useRef(queryParams.get('c')!);
  const [password,] = useState<string>(AppSettings.CATALINA_PASSWORD);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [openErrorModel, setOpenErrorModel] = useState<boolean>(false);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    cardNo.current = queryParams.get('c')!;
  }, []);


  const getFavoriteStoresAndTriggerGTM = () => {
    MembersService.getFavoriteStores().then((favresponse) => {
      const mappedStoreId = DataService.getStoreMapping(queryParams.get('s'));
      let storesList = favresponse.data.map((b: { id: any }) => b.id).join("_");
      let starStore = favresponse.data.filter((f: { id: any; }) => f.id === Number(mappedStoreId))[0];
      if (starStore) {
        props.setFavoriteStores(favresponse.data)
        localStorage.removeItem("fav-store");
        localStorage.setItem('fav-store', JSON.stringify(starStore))
        props.setSelectedStore(starStore);
      }
      let userData: any = localStorage.getItem("user-data")
      if (userData) userData = JSON.parse(userData);

      GTMService.triggerGTMLogin(storesList, starStore, cardNo)
    });
  }
  const handleSignup = () => {
    localStorage.removeItem('from-terms')
    setLoading(true);
    GTMService.triggerGTMSignup(cardNo);
    AuthService.signup(cardNo.current + AppSettings.CATALINA_DOMAIN, password, [{
      ref: cardNo.current!,
      retailer_id: (CURRENT_ENV === 'prod' ? 19 : 59)
    }]).then((response) => {
      setLoading(true);
      AuthService.login(cardNo.current + AppSettings.CATALINA_DOMAIN, password)
        .then(async (response) => {
          localStorage.setItem(
            "user-data",
            JSON.stringify(response.data)
          );
          if (queryParams.get('s') !== null && /^\d+$/.test(queryParams.get('s')!)) {
            const mappedStoreId = DataService.getStoreMapping(queryParams.get('s'));
            if (mappedStoreId) {
              MembersService.addStoreToFavs(mappedStoreId, null).then((response) => {
                getFavoriteStoresAndTriggerGTM()
              })
                .catch(
                  (error) => {
                    console.log(error);
                    // if (error.response.data.code[0] === 2813 || error.response.data.code[0] === 2837) {
                    // console.log("existing store")
                    getFavoriteStoresAndTriggerGTM()
                  }
                );
            }
          } else {
            localStorage.setItem('from-terms', 'true')
          }
          return MembersService.getFavoriteStores();
        })
        .then((response) => {
          setLoading(false);
          history.push("/home");
        })
        .catch((err) => {
          setLoading(false);
          setErrorMessage(err.response.data.message ? err.response.data.message : err.response.data.error);
          setOpenErrorModel(true);
        });
    })
      .catch((err) => {
        setLoading(false);
        setErrorMessage(err.response.data.message ? err.response.data.message : err.response.data.error);
        setOpenErrorModel(true);
      });
  }
  return (
    <ScrollpageWithHeader title="メルアドレスを登録" id="terms">
      <div className="px-6 mt-6">
        {openErrorModel && (
          <div className="text-red-600 text-xs -mt-3 mb-10">{errorMessage}</div>
        )}
        {/* <IonLabel className="account-editing">メールアドレス</IonLabel> */}
        <div className="mt-6"></div>
        <div className="text font-bold text-sm  dark:bg-white dark:text-black" style={{ marginTop: "3.375rem" }}>クータッチ利用規約</div>
        <div className="text-sm text-black flex mt-2 overflow-auto h-96 ...">
          <div className="text-xs mt-4 inline-block">
            <NewUpdatedTerms />
            2022年 4月 25日
          </div>
        </div>
      </div>
      {/*<div>*/}
      {/*  <IonItem lines="none" className="mt-6 flex items-center">*/}
      {/*    */}
      {/*    <IonCheckbox*/}
      {/*      checked={checked}*/}
      {/*      onIonChange={(e) => setChecked(e.detail.checked)}*/}
      {/*    />*/}
      {/*    <IonLabel>利用規約に同意する</IonLabel>*/}
      {/*  </IonItem>*/}
      {/*</div>*/}
      <div className="pt-6 dark:bg-white dark:text-black">
        <fieldset className="py-3">
      <div className="px-4">
        <Checkbox
            checked={checked}
            onChange={(value: any) => setChecked(value)}
            icon={<CheckIcon className="h-5 w-5" />}
            id="comments"
            aria-describedby="comments-description"
            borderColor="rgba(0,0,0,0.3)"
            borderRadius={5}
            size={18}
            label="利用規約に同意する"
        />
      </div>

        </fieldset>
      </div>
      <div className="px-4 my-6">
        <IonButton
          disabled={
            !email || !checked
          }
          onClick={handleSignup}
          expand="block"
          color="light"
        >
          送信する
        </IonButton>
      </div>
    </ScrollpageWithHeader>
  );
};
export const TermsPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(TermsPageComponent);