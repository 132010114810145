import { RadioGroup } from "@headlessui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import {
  fetchCategories,
  setSelectedCategory,
} from "../../redux/categories/categories.actions";
import { IonLabel, IonSegment, IonSegmentButton } from "@ionic/react";
import "./segment.css";
import DataService from "../../services/data.service";
import GTMService from "../../services/gtm.service";
import {setEvent} from "../../redux/global/global.actions";
import {setBasketLoading, setOfferListLoading} from "../../redux/offer-list/offer-list.actions";
const filters = [
  { name: "すべて" },
  { name: "ホーム&キッチン" },
  { name: "ヘア&ビューティー" },
  { name: "食品" },
  { name: "飲料" },
  { name: "お酒" },
];

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

const mapStateToProps = (state: any) => {
  return {
    state:state,
    categories: state.categories.categories,
    selectedCategory: state.categories.selectedCategory,
    categoriesLoaded: state.categories.categoriesLoaded,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    fetchCategories: () => dispatch(fetchCategories()),
    setSelectedCategory: (data: any) => dispatch(setSelectedCategory(data)),
    setEvent: (event:any) => dispatch(setEvent(event)),
    setBasketLoading: (loading:boolean) => dispatch(setBasketLoading(loading)),
    setOfferListLoading: (loading:boolean) => dispatch(setOfferListLoading(loading)),
  };
};
interface Props {
  state:any;
  categoriesLoaded: boolean;
  fetchCategories: Function;
  setSelectedCategory: Function;
  setOfferListLoading: Function;
  fetchOffers?: Function;
  categories: Array<{ id: number; name: string; tag: string }>;
  selectedCategory: { id: number; name: string; tag: string };
  setBasketLoading: Function;
  setEvent: Function;
}

export const SegmentFiltersComponent = (props: Props) => {
  const [selected, setSelected] = useState(filters[2]);
  const { categories, selectedCategory } = props;
  const { t } = useTranslation();
  return (
    <RadioGroup
      value={props.selectedCategory}
      onChange={(data) => {
        props.setSelectedCategory(data.id);
        props.setBasketLoading(false)
        props.setOfferListLoading(true)
        props.setEvent("CATEGORY_CHANGED");
      }}
      className="py-4 pt-8 dark:bg-white dark:text-black"
    >
      <RadioGroup.Label className="text-sm pl-4">
        {t("category_selection")}
      </RadioGroup.Label>

      <div className="whitespace-nowrap segment-scrollable overflow-x-auto">
        {props.categories.map((filter, index) => (
          <RadioGroup.Option
            key={filter.name}
            value={filter}
            className={({ active, checked }: any) =>
              classNames(
                "inline-block",
                "cursor-pointer focus:outline-none",
                !checked
                  ? "border-gray text-okuwa_blue"
                  : "bg-okuwa_blue text-white",
                "border rounded-md py-2 px-3 text-xs mx-2 mt-3",
                index === 0 ? "ml-4" : "",
                index === props.categories.length - 1 ? "mr-4" : ""
              )
            }
          >
            <RadioGroup.Label as="p">{filter.name}</RadioGroup.Label>
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
    // <div>
    //   <IonSegment
    //       mode="md"
    //       scrollable
    //       value={selectedCategory ? `${selectedCategory.id}` : null}
    //   >
    //     {categories.map((category) => {
    //       return (
    //           <IonSegmentButton
    //               key={category.id}
    //               value={`${category.id}`}
    //               onClick={() => {
    //                 props.setSelectedCategory(category.id);
    //                 if (props.fetchOffers) {
    //                   props.fetchOffers(category.id);
    //                 }
    //                 // props.setEvent("CATEGORY_CHANGED")
    //                 // eventBus.dispatch("GTM_EVENT", {
    //                 //   eventName: "CATEGORY_CHANGED",
    //                 //   data: props.state,
    //                 // });
    //               }}
    //           >
    //             <IonLabel>{category.name}</IonLabel>
    //           </IonSegmentButton>
    //       );
    //     })}
    //   </IonSegment>
    // </div>
  );
};
export const SegmentFilters = connect(
  mapStateToProps,
  mapDispatchToProps
)(SegmentFiltersComponent);
