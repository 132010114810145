import {
  REMOVE_EVENT,
  SET_APP_LOADING, SET_EVENT,SET_FIRST_LOAD
} from "./global.types";

export const setAppLoading = (data) => {
  return {
    type: SET_APP_LOADING,
    payload: data,
  };
};


export const setEvent = (data) => {
  return {
    type: SET_EVENT,
    payload: data,
  };
};

export const setFirstLoad = (data) => {
  return {
    type: SET_FIRST_LOAD,
    payload: data,
  };
};


export const removeEvent = () => {
  return {
    type: REMOVE_EVENT,
    payload: null,
  };
};
