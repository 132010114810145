import { ChevronRightIcon } from "@heroicons/react/outline";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { StoreAddOrDeleteModal } from "../../components/store-add-or-delete-modal/store-add-or-delete-modal";
import { setAppLoading } from "../../redux/global/global.actions";
import {
  addToFavStores,
  fetchCities,
  fetchPrefectures,
  fetchStores,
  setCity,
  setPrefecture,
  setStore,
} from "../../redux/retailers/retailers.actions";
import { fetchFavoriteStores, setStoreLoading } from "../../redux/stores/stores.actions";
import pin from "../store-tab-favorites/pin.png";
import DataService from "../../services/data.service";
import {useIonToast, useIonViewWillEnter} from "@ionic/react";
import GTMService from "../../services/gtm.service";
const CURRENT_ENV = process.env.REACT_APP_ENV as string;

const mapStateToProps = (state: any) => {
  return {
    state: state,
    favoriteStores: state.stores.favoriteStores,
    selectedStore: state.stores.selectedStore,
    favoriteStoresLoaded: state.stores.favoriteStoresLoaded,
    storesLoaded: state.stores.storesLoaded,
    loading: state.stores.loading,
    isAppLoading: state.global.isAppLoading,
    prefectures: state.retailers.prefectureList,
    prefecture: state.retailers.prefecture,
    cities: state.retailers.cities,
    city: state.retailers.city,
    stores: state.retailers.stores,
    store: state.retailers.store,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    fetchFavoriteStores: () => dispatch(fetchFavoriteStores()),
    setAppLoading: (data: any) => dispatch(setAppLoading(data)),
    fetchPrefectures: (retailer: any) => dispatch(fetchPrefectures(retailer)),
    setPrefecture: (prefecture: any) => dispatch(setPrefecture(prefecture)),
    setCity: (city: any) => dispatch(setCity(city)),
    setStore: (store: any) => dispatch(setStore(store)),
    setStoreLoading: (loading: any) => dispatch(setStoreLoading(loading)),
    addToFavStores: (store: any) => dispatch(addToFavStores(store)),
    fetchCities: (retailer: any, prefecture: any) =>
      dispatch(fetchCities(retailer, prefecture)),
    fetchStores: (retailer: any, prefecture: any, city: any) =>
      dispatch(fetchStores(retailer, prefecture, city)),
  };
};

interface Props {
  state: any;
  selectedStore: any;
  favoriteStores: any[];
  storesLoaded: boolean;
  favoriteStoresLoaded: boolean;
  isAppLoading: boolean;
  fetchFavoriteStores: Function;
  setAppLoading: Function;
  prefectures: any[];
  prefecture: any;
  cities: any[];
  city: any;
  stores: any[];
  store: any;
  filters: any;
  setSelectedCategory: Function;
  setStoreLoading: Function;
  fetchPrefectures: Function;
  setPrefecture: Function;
  setCity: Function;
  setStore: Function;
  fetchCities: Function;
  fetchStores: Function;
  addToFavStores: Function;
}

function getPrefectureRemoveNumber(prefecture: string) {
  let split = prefecture.split('-');
  split.shift();
  return split.join('-')
}

const StoreSelectionFlowComponent = (props: Props) => {
  const [present, dismiss] = useIonToast();
  useIonViewWillEnter(() => {
    props.setPrefecture("")
    props.setCity("")
  }, []);

  useEffect(() => {
    props.setPrefecture("")
    props.setCity("")
  }, []);

  // console.log({ prefectures: props.prefectures });
  const { t } = useTranslation();

  const [selectedStore, setSelectedStore] = useState<any>(null);

  const SelectedBadges = () => {
    return (
      <div className="p-4 underline dark:text-black">
        {/* {props.prefecture && (
          <span className="inline-flex rounded-md items-center py-0.5 pl-2.5 pr-1 text-sm font-medium bg-gray-100 text-gray-700">
            {props.prefecture}
            <button
              onClick={() => {
                props.setPrefecture(null);
                props.setCity(null);
              }}
              type="button"
              className="flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-gray-400 hover:bg-gray-200 hover:text-gray-500 focus:outline-none focus:bg-gray-500 focus:text-white"
            >
              <XIcon className="w-3" />
            </button>
          </span>
        )} */}

        {props.prefecture && (
          <span
            onClick={() => {
              props.setPrefecture(null);
              props.setCity(null);
            }}
            className="py-0.5 pl-2.5 pr-1 text-sm font-medium text-gray-700"
          >
            {getPrefectureRemoveNumber(props.prefecture) ? getPrefectureRemoveNumber(props.prefecture) : props.prefecture}
          </span>
        )}

        {/* {props.city && (
          <span className="ml-2 inline-flex rounded-md items-center py-0.5 pl-2.5 pr-1 text-sm font-medium bg-gray-100 text-gray-700">
            {props.city}
            <button
              onClick={() => {
                props.setCity(null);
              }}
              type="button"
              className="flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-gray-400 hover:bg-gray-200 hover:text-gray-500 focus:outline-none focus:bg-gray-500 focus:text-white"
            >
              <XIcon className="w-3" />
            </button>
          </span>
        )} */}

        {props.city && (
          <span className="py-0.5 pl-2.5 pr-1 text-sm font-medium text-gray-700">
            /
          </span>
        )}

        {props.city && (
          <span
            onClick={() => {
              props.setCity(null);
            }}
            className="py-0.5 pl-2.5 pr-1 text-sm font-medium text-gray-700"
          >
            {props.city}
          </span>
        )}
      </div>
    );
  };

  if (!props.prefecture) {
    return (
      <div>
        <div className="p-4 dark:text-black">{t("select_prefecture")}</div>
        <div className="grid grid-cols-1 divide-y px-4 dark:text-black">
          {props.prefectures.map((prefecture) => {
            return (
              <div
                key={prefecture}
                onClick={() => {
                  props.setPrefecture(prefecture);
                  props.fetchCities(CURRENT_ENV === 'prod' ? { id: 19 } : { id: 59 }, prefecture);
                }}
                className="py-4 active:bg-gray-200 text-left flex items-center dark:text-black"
              >
                <div className="left-icon w-7">
                  <img src={pin} className="w-5" />
                </div>
                <div className="pl-3 flex-1">
                  {getPrefectureRemoveNumber(prefecture) ? getPrefectureRemoveNumber(prefecture) : prefecture}
                </div>
                <ChevronRightIcon className="w-5 text-gray-600" />
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  if (props.prefecture && !props.city) {
    return (
      <div>
        <SelectedBadges />
        <div className="p-4 dark:text-black">{t("select_city")}</div>
        <div className="grid grid-cols-1 divide-y px-4 dark:text-black">
          {props.cities.map((city) => {
            return (
              <div
                key={city}
                onClick={() => {
                  props.setCity(city);
                  props.fetchStores((CURRENT_ENV === 'prod' ? { id: 19 } : { id: 59 }), props.prefecture, city);
                }}
                className="py-4 active:bg-gray-200 text-left flex items-center dark:text-black"
              >
                <div className="left-icon w-7">
                  <img src={pin} className="w-5" />
                </div>
                <div className="pl-3 flex-1">{city}</div>
                <ChevronRightIcon className="w-5 text-gray-600" />
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  if (props.prefecture && props.city) {
    return (
      <div>
        {selectedStore && (
          <StoreAddOrDeleteModal
            mainButton="RIGHT"
            hideLeftButton={true}
            mode="ADD"
            title="選択店舗"
            rightText="お気に入り店舗に追加"
            leftText="Add"
            close={() => setSelectedStore(null)}
            isOpen={!!selectedStore}
            onLeftClick={() => setSelectedStore(null)}
            onCancel={() => setSelectedStore(null)}
            onRightClick={() => {
              // console.log("Add Store");
              props.setStore(selectedStore);
              props.addToFavStores(selectedStore.id);
              setSelectedStore(false);
              props.setStoreLoading(true);
              localStorage.removeItem("fav-store");
              localStorage.setItem('fav-store', JSON.stringify(selectedStore))
              props.setCity(null)
              props.setPrefecture(null)
              props.setSelectedCategory(props.filters[0]);
              GTMService.triggerGTM({
                eventName: 'storeRegistered',
                data: { ...props.state, selectedStore: selectedStore },
              })
              setTimeout(() => props.fetchFavoriteStores(), 1000);
            }}
            store={selectedStore}
          />
        )}

        <SelectedBadges />
        <div className="p-4 dark:text-black">{t("select_store")}</div>
        <div className="grid grid-cols-1 divide-y px-4 dark:text-black">
          {props.stores.map((store) => {
            return (
              <div
                key={store.id}
                onClick={() => {
                  // setSelectedStore(store);
                  props.setStore(store);
                  props.addToFavStores(store.id);
                  setSelectedStore(false);
                  props.setStoreLoading(true);
                  localStorage.removeItem("fav-store");
                  localStorage.setItem('fav-store', JSON.stringify(store))
                  props.setCity(null)
                  props.setPrefecture(null)
                  props.setSelectedCategory(props.filters[0]);
                  GTMService.triggerGTM({
                    eventName: 'storeRegistered',
                    data: { ...props.state, selectedStore: store },
                  })
                  present({
                    message: "お気に入り店舗に登録されました",
                    duration: 2500,
                    cssClass: 'toasty'
                  });
                  setTimeout(() => props.fetchFavoriteStores(), 1000);
                }}
                className="py-4 active:bg-gray-200 text-left flex items-center"
              >
                <div className="left-icon w-7">
                  <img src={pin} className="w-5" />
                </div>
                <div className="pl-3 flex-1">{store.name}</div>
                <ChevronRightIcon className="w-5 text-gray-600" />
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  return <div>Invalid State</div>;
};

export const StoreSelectionFlow = connect(
  mapStateToProps,
  mapDispatchToProps
)(StoreSelectionFlowComponent);
