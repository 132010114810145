import { IonPage } from "@ionic/react";
import { Link } from "react-router-dom";

const pages = [
  {
    name: "Home",
    name2: "Home",
    path: "/home",
  },
  {
    name: "Product details",
    name2: "Product details",
    path: "/product-details",
  },
  {
    name: "Cart",
    name2: "Cart",
    path: "/cart",
  },
  {
    name: "Account",
    name2: "account",
    path: "/account",
  },
  {
    name: "Points",
    name2: "Points",
    path: "/points",
  },
];

export const PagesMenu = () => {
  return (
    <IonPage className="pt-16">
      <ul role="list" className="divide-y divide-gray-200 px-4 overflow-auto">
        {pages.map((page, index) => (
          <Link to={page.path}>
            <li key={index} className="py-4 flex">
              <img
                className="h-10 w-10 rounded-full p-1"
                src="https://raw.githubusercontent.com/PKief/vscode-material-icon-theme/main/logo.png"
                alt=""
              />
              <div className="ml-3">
                <p className="text-sm font-medium text-gray-900">{page.name}</p>
                <p className="text-sm text-gray-500">{page.name2}</p>
              </div>
            </li>
          </Link>
        ))}
      </ul>
    </IonPage>
  );
};
