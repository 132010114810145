import { ChevronRightIcon, XIcon } from "@heroicons/react/outline";
import { LocationMarkerIcon } from "@heroicons/react/solid";
import {IonSearchbar, useIonToast} from "@ionic/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, useStore } from "react-redux";
import { StoreAddOrDeleteModal } from "../../components/store-add-or-delete-modal/store-add-or-delete-modal";
import { setAppLoading } from "../../redux/global/global.actions";
import {
  addToFavStores,
  fetchCities,
  fetchPrefectures,
  fetchStores,
  setCity,
  setPrefecture,
  setStore,
} from "../../redux/retailers/retailers.actions";
import { fetchFavoriteStores, setStoreLoading } from "../../redux/stores/stores.actions";

import pin from "../store-tab-favorites/pin.png";
import DataService from "../../services/data.service";
import GTMService from "../../services/gtm.service";
var hanzen= require('hanzen');

const mapStateToProps = (state: any) => {
  return {
    state: state,
    favoriteStores: state.stores.favoriteStores,
    selectedStore: state.stores.selectedStore,
    favoriteStoresLoaded: state.stores.favoriteStoresLoaded,
    storesLoaded: state.stores.storesLoaded,
    isAppLoading: state.global.isAppLoading,
    prefectures: state.retailers.prefectureList,
    prefecture: state.retailers.prefecture,
    loading: state.stores.loading,
    cities: state.retailers.cities,
    city: state.retailers.city,
    stores: state.retailers.stores,
    all_stores: state.retailers.all_stores,
    store: state.retailers.store,
    cards: state.cards.holders,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    fetchFavoriteStores: () => dispatch(fetchFavoriteStores()),
    setAppLoading: (data: any) => dispatch(setAppLoading(data)),
    fetchPrefectures: (retailer: any) => dispatch(fetchPrefectures(retailer)),
    setPrefecture: (prefecture: any) => dispatch(setPrefecture(prefecture)),
    setCity: (city: any) => dispatch(setCity(city)),
    setStore: (store: any) => dispatch(setStore(store)),
    addToFavStores: (store: any) => dispatch(addToFavStores(store)),
    setStoreLoading: (loading: any) => dispatch(setStoreLoading(loading)),
    fetchCities: (retailer: any, prefecture: any) =>
      dispatch(fetchCities(retailer, prefecture)),
    fetchStores: (retailer: any, prefecture: any, city: any) =>
      dispatch(fetchStores(retailer, prefecture, city)),
  };
};

interface Props {
  state: any;
  selectedStore: any;
  cards: any;
  favoriteStores: any[];
  storesLoaded: boolean;
  favoriteStoresLoaded: boolean;
  isAppLoading: boolean;
  setStoreLoading: Function;
  filters: any;
  setSelectedCategory: Function;
  fetchFavoriteStores: Function;
  setAppLoading: Function;
  prefectures: any[];
  prefecture: any;
  cities: any[];
  city: any;
  stores: any[];
  all_stores: any[];
  store: any;
  fetchPrefectures: Function;
  setPrefecture: Function;
  setCity: Function;
  setStore: Function;
  fetchCities: Function;
  fetchStores: Function;
  addToFavStores: Function;
}

const StoreSelectionSearchComponent = (props: Props) => {
  const { t } = useTranslation();
  const [present, dismiss] = useIonToast();
  const [selectedStore, setSelectedStore] = useState<any>(null);
  const [searchText, setSearchText] = useState("");
  const [searchTextFullWidth, setSearchTextFullWidth] = useState("");
  let cardNumber = props.cards && props.cards.length > 0 ? props.cards[0].ref : 0

  const convertKanaF2H = (str: any) => {
    str = replaceStringByArrayOfIndex(str, WKANA, HKANA);
    return str;
  }

  const replaceStringByArrayOfIndex = (str: any, src: string | any[], dest: any[]) => {
    var len = src.length;
    for (var i = 0; i < len; i++) {
      str = replaceAll(str, src[i], dest[i])
    }
    return str;
  }

  function replaceAll(target: string, from: any, to: any) {
    if (target.indexOf(from) < 0) {
      return target;
    } else {
      return target.split(from).join(to)
    }
  }

  var HKANA = new Array(
    "ｶﾞ", "ｷﾞ", "ｸﾞ", "ｹﾞ", "ｺﾞ", "ｻﾞ", "ｼﾞ", "ｽﾞ", "ｾﾞ", "ｿﾞ",
    "ﾀﾞ", "ﾁﾞ", "ﾂﾞ", "ﾃﾞ", "ﾄﾞ", "ﾊﾞ", "ﾋﾞ", "ﾌﾞ", "ﾍﾞ", "ﾎﾞ", "ｳﾞ", //濁音
    "ﾊﾟ", "ﾋﾟ", "ﾌﾟ", "ﾍﾟ", "ﾎﾟ", //半濁音
    "ｧ", "ｨ", "ｩ", "ｪ", "ｫ", "ｬ", "ｭ", "ｮ", "ｯ", "ｰ", "", // 小文字
    "ｱ", "ｲ", "ｳ", "ｴ", "ｵ", "ｶ", "ｷ", "ｸ", "ｹ", "ｺ", // 50音
    "ｻ", "ｼ", "ｽ", "ｾ", "ｿ", "ﾀ", "ﾁ", "ﾂ", "ﾃ", "ﾄ",
    "ﾅ", "ﾆ", "ﾇ", "ﾈ", "ﾉ", "ﾊ", "ﾋ", "ﾌ", "ﾍ", "ﾎ",
    "ﾏ", "ﾐ", "ﾑ", "ﾒ", "ﾓ", "ﾔ", "ﾕ", "ﾖ",
    "ﾗ", "ﾘ", "ﾙ", "ﾚ", "ﾛ", "ﾜ", "", "ｦ", "", "ﾝ" // 50音 end
  );

  var WKANA = new Array(
    "ガ", "ギ", "グ", "ゲ", "ゴ", "ザ", "ジ", "ズ", "ゼ", "ゾ",
    "ダ", "ヂ", "ヅ", "デ", "ド", "バ", "ビ", "ブ", "ベ", "ボ", "ヴ", //濁音
    "パ", "ピ", "プ", "ペ", "ポ", //半濁音
    "ァ", "ィ", "ゥ", "ェ", "ォ", "ャ", "ュ", "ョ", "ッ", "ー", "ヮ", // 小文字
    "ア", "イ", "ウ", "エ", "オ", "カ", "キ", "ク", "ケ", "コ", // 50音 start
    "サ", "シ", "ス", "セ", "ソ", "タ", "チ", "ツ", "テ", "ト",
    "ナ", "ニ", "ヌ", "ネ", "ノ", "ハ", "ヒ", "フ", "ヘ", "ホ",
    "マ", "ミ", "ム", "メ", "モ", "ヤ", "ユ", "ヨ",
    "ラ", "リ", "ル", "レ", "ロ", "ワ", "ヰ", "ヲ", "ヱ", "ン" // 50音 end
  );

  return (
    <div>
      <div className="sticky bg-white" style={{ top: 50 }}>
        <div className="mx-4 py-4">
          <IonSearchbar
            className="shadow-custom store-searchbar rounded-md dark:text-gray-600"
            value={searchText}
            mode="ios"
            placeholder="店舗名を入力"
            onIonChange={(e) => {
              // setSearchTextFullWidth(convertKanaF2H(e.detail.value!))
              setSearchText(e.detail.value!)
            }}
          ></IonSearchbar>
        </div>
      </div>
      <div className="grid grid-cols-1 divide-y px-4">
        {props.all_stores
          .filter((store) => store.name.indexOf(hanzen.toHankaku(searchText)) >= 0)
          .map((store) => {
            return (
              <div
                key={store.id}
                onClick={() => {
                  // setSelectedStore(store);
                  GTMService.triggerGTM({
                    eventName: 'storeRegistered',
                    data: { ...props.state, selectedStore: store },
                  })
                  props.setStore(store);
                  localStorage.removeItem("fav-store");
                  localStorage.setItem('fav-store', JSON.stringify(store))
                  props.setStoreLoading(true)
                  props.addToFavStores(store.id);
                  props.setSelectedCategory(props.filters[0]);
                  setSelectedStore(false);
                  present({
                    message: "お気に入り店舗に登録されました",
                    duration: 2500,
                    cssClass: 'toasty'
                  });
                  setTimeout(() => props.fetchFavoriteStores(), 1000);
                }}
                className="p-4 active:bg-gray-200 text-left flex items-center pl-2 dark:text-black"
              >
                <div className="left-icon w-7">
                  <img src={pin} className="w-5" />
                </div>
                <div className="pl-3 flex-1">{store.name}</div>
                {/* <ChevronRightIcon className="w-5 text-gray-600" /> */}
              </div>
            );
          })}
      </div>
      <div>
        {selectedStore && (
          <StoreAddOrDeleteModal
            mainButton="RIGHT"
            mode="ADD"
            title="選択店舗"
            rightText="お気に入り店舗に追加"
            hideLeftButton={true}
            leftText="Add"
            close={() => {
              setSelectedStore(null)
            }}
            isOpen={!!selectedStore}
            onLeftClick={() => {
              setSelectedStore(null)
            }}
            onCancel={() => setSelectedStore(null)}
            onRightClick={() => {
              GTMService.triggerGTM({
                eventName: 'storeRegistered',
                data: { ...props.state, selectedStore: selectedStore },
              })
              props.setStore(selectedStore);
              localStorage.removeItem("fav-store");
              localStorage.setItem('fav-store', JSON.stringify(selectedStore))
              props.setStoreLoading(true)
              props.addToFavStores(selectedStore.id);
              props.setSelectedCategory(props.filters[0]);
              setSelectedStore(false);
              setTimeout(() => props.fetchFavoriteStores(), 1000);
            }}
            store={selectedStore}
          />
        )}
      </div>
    </div>
  );
};

export const StoreSelectionSearch = connect(
  mapStateToProps,
  mapDispatchToProps
)(StoreSelectionSearchComponent);
