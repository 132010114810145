import { combineReducers } from "redux";

import counterReducer from "./counter/counter.reducer";
import offerDetailsReducer from "./offer-details/offer-details.reducer";
import offerListReducer from "./offer-list/offer-list.reducer";
import categoriesReducer from "./categories/categories.reducer";
import storesReducer from "./stores/stores.reducer";
import holderCardsReducer from "./holder-cards/holder-cards.reducer";
import walletReducer from "./wallet/wallet.reducer";
import allwalletReducer from "./allwallet/allwallet.reducer";
import tabsReducer from "./tabs/tabs.reducer";
import retailersReducer from "./retailers/retailers.reducer";
import globalReducer from "./global/global.reducer";

const rootReducer = combineReducers({
  counter: counterReducer,
  offerDetails: offerDetailsReducer,
  offerList: offerListReducer,
  categories: categoriesReducer,
  stores: storesReducer,
  cards: holderCardsReducer,
  wallet: walletReducer,
  allwallet: allwalletReducer,
  tabs: tabsReducer,
  global: globalReducer,
  retailers: retailersReducer
});

export default rootReducer;
