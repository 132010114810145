import {
  SET_FAVORITE_STORES,
  SET_SELECTED_STORE,
  SET_STORE_LOADING,
} from "./stores.types";

const INITIAL_STATE = {
  favoriteStores: [],
  favoriteStoresLoading: false,
  loading: true,
  selectedStore: null,
  favoriteStoresLoaded: false
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_FAVORITE_STORES:
      let starStore = JSON.parse(localStorage.getItem('fav-store'));
      if(!starStore){
        // selectedStore: action.payload.length > 0 ? action.payload[0] : null,
        starStore = action.payload.length > 0 ? action.payload[0] : null
        localStorage.setItem('fav-store', JSON.stringify(starStore))

      }
      return {
        ...state,
        favoriteStores: action.payload,
        selectedStore: starStore,
        favoriteStoresLoaded: true,
        loading: false
      };

    case SET_SELECTED_STORE:
      let store = state.favoriteStores.filter(
          (c) => c.id === action.payload.id
      )[0]
      localStorage.setItem('fav-store', JSON.stringify(store))
      return {
        ...state,
        selectedStore: store,
      };

    case SET_STORE_LOADING:
      // console.log('SETTING LOADING TO: ', action);
      return {
        ...state,
        favoriteStoresLoaded: action.payload,
        loading: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
