import axios from "axios";
import storeMappingDev from '../store-mapping_Dev.json';
import storeMappingProd from '../store-mapping_Prod.json';
import {errorFn, Fn200, preRequestFn, requestErrorFn} from "./errorfunctions";
import UtilityService from "./utility";

let prefecture: any = null;
let retailer: any = null;
let city: any = null;
let area: any = null;
let store: any = null;
let stores: any = [];
let card: any = null;
let favMode: any = false;
let holders: any = [];
let basketpoints = 0;
const CURRENT_ENV = process.env.REACT_APP_ENV as string;
const OCP_API_SUBSCRIPTION_KEY = process.env.REACT_APP_OCP_API_SUBSCRIPTION_KEY as string;
const RETAILERS_URL = process.env.REACT_APP_RETAILERS_URL as string;
const STORES_URL = process.env.REACT_APP_STORES_URL as string;

axios.interceptors.request.use(preRequestFn, requestErrorFn);

// Add a response interceptor
axios.interceptors.response.use(Fn200, errorFn);


const getRequestHeaders = ():any => {
    const user = JSON.parse(localStorage.getItem("user-data")!);
    if (user && user.access_token) {
        return {
            "Ocp-Apim-Subscription-Key": OCP_API_SUBSCRIPTION_KEY,
            "Ocp-Apim-Trace": "true",
            "Content-Type": "application/json",
            "X-Frame-Options": "sameorigin",
            "Cache-Control": "no-cache,no-store",
            "Expires": 0,
            "Pragma": "no-cache",
            "Strict-Transport-Security": "max-age=86400; includeSubDomains",
            "X-Content-Type-Options": "nosniff",
            "X-XSS-Protection": "1; mode=block",
            Authorization: `Bearer ${user.access_token}`,
        };
    } else {
        if (window.location.pathname == '/1') {

        } else {
            window.location.href = '/1'
        }
    }

};

const getUser = () => {
    const user = JSON.parse(localStorage.getItem("user-data")!);
    if (user) {
        return user
    } else {
        if (window.location.pathname == '/1') {

        } else {
            window.location.href = '/1'
        }
    }
};

const setServiceDataFromStorage = () => {
    const data = JSON.parse(window.localStorage.getItem("service-data1") || "{}");
    prefecture = data.prefecture;
    retailer = data.retailer;
    city = data.city;
    area = data.area;
    store = data.store;
    stores = data.stores || [];
    card = data.card;
    favMode = data.favMode || false
    holders = data.holders || [];
    basketpoints = data.basketpoints || 0;
}

setServiceDataFromStorage();
const updateServiceDataToStorage = () => {
    const data = {
        prefecture,
        retailer,
        city,
        area,
        store,
        stores,
        card,
        holders,
        basketpoints,
        favMode
    }
    window.localStorage.setItem("service-data1", JSON.stringify(data));
}

const DataService = {
    getRetailers: () => {
        return axios.get(`${RETAILERS_URL}?per_page=1000`, {
            timeout: UtilityService.timeOut,
            headers: getRequestHeaders(),
        })
    },
    getPrefectures: (retailer: any) => {
        return axios.get(`${RETAILERS_URL}/${retailer.id}/stores/prefectures?per_page=1000`, {
            timeout: UtilityService.timeOut,
            headers: getRequestHeaders(),
        })
    },
    getCities: (retailer: any, prefecture: any) => {
        return axios.get(`${RETAILERS_URL}/${retailer.id}/stores/prefectures/cities?prefecture=${prefecture}&per_page=1000`, {
            timeout: UtilityService.timeOut,
            headers: getRequestHeaders(),
        })
    },
    getAreas: (retailer: any, prefecture: any, city: any) => {
        return axios.get(`${RETAILERS_URL}/${retailer.id}/stores/prefectures/cities/areas?prefecture=${prefecture}&city=${city}&per_page=1000`, {
            timeout: UtilityService.timeOut,
            headers: getRequestHeaders(),
        })
    },
    getStores: (retailer: any, prefecture: any, city: any) => {
        return axios.get(`${STORES_URL}?retailer_id=${retailer.id}&prefecture=${prefecture}&city=${city}&per_page=1000`, {
            timeout: UtilityService.timeOut,
            headers: getRequestHeaders(),
        });
    },
    getAllStoresNoFilter: (retailer: any) => {
        return axios.get(`${STORES_URL}?retailer_id=${retailer.id}&per_page=1000`, {
            timeout: UtilityService.timeOut,
            headers: getRequestHeaders(),
        });
    },
    getStoresCountForRetailer: (retailer: any) => {
        const user = getUser();

        return axios.get(`${STORES_URL}?retailer_id=${retailer.id}&member_id=${user.id}&per_page=10`, {
            timeout: UtilityService.timeOut,
            headers: getRequestHeaders(),
        });
    },
    getStoreMapping: (storeId: any) => {
        // To do: for now pushing same json because prod is point to test backend
        // @ts-ignore
        return CURRENT_ENV === "prod" ? storeMappingProd[storeId] : storeMappingDev[storeId];
    }
};

export default DataService;
