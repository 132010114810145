import { useTranslation } from "react-i18next";
import okuwa_full from "./okuwa_full.png";

export const TopBanner = () => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col items-center justify-center p-6 bg-okuwa_bg">
      <img className="h-8" src={okuwa_full} />
      <div className="pt-4 dark:text-black">{t("Find your favorite store")}</div>
    </div>
  );
};
