import { IonPage, IonHeader, IonToolbar, IonText } from "@ionic/react";
import { FC, ReactNode } from "react";
import "./scrollpage-with-header.scss";
import { useHistory } from "react-router-dom";

interface Props {
  id: string;
  title: string;
  children:any;
  showSelectStoreIcon?: boolean;
  fixedContentBelowHeader?: ReactNode;
  showBackButton?: boolean;
  onCloseClick?: Function;
}


export const ScrollpageWithHeader: FC<Props> = (props) => {
  const history = useHistory();
  const { title, showBackButton, fixedContentBelowHeader } = props;
  return (
    <IonPage id={props.id}>
      <IonHeader mode="ios">
        {props.showSelectStoreIcon && (
          <IonToolbar mode="ios" className="catalina-scrollpage-header">
              <div>
                  <div
                      className="p-4 mx-3 mb-2 rounded-md block"
                      style={{ background: "#FFF4D8" }}
                  >
                      <IonText
                          className="text-xs pt-1 "
                          color="danger"
                          style={{ color: "#FF772A", fontSize: 13, fontWeight: 600 }}
                      >
                          お店とカードを登録してお買い物でポイントを還元
                      </IonText>
                  </div>
                  <div className="pointer"></div>
              </div>
          </IonToolbar>
        )}
      </IonHeader>
      <div className="flex flex-col flex-1 bg-white overflow-auto">
        {fixedContentBelowHeader && <div>{fixedContentBelowHeader}</div>}
        <div className="flex flex-col flex-1 overflow-auto">
          {props.children}
        </div>
      </div>
    </IonPage>
  );
};
