export const SAMPLE_WALLET: any = {
  pending: [],
  refused: [],
  to_be_paid: [],
  paid: [
    {
      id: 0,
      type: "string",
      expired_at: "2021-10-22T11:05:34.613Z",
      amount: 11,
      offer: {
        id: 1,
        ref: "COUP01",
        discount_value: 5.5,
        title: "Coca Cola Zero",
        subtitle: "Pack of 6 bottles",
        picture_url: "http://xxx.com/xxxxxx/coca_cola.png",
        best: true,
        brand_id: 10,
        promocode_value: 3,
        points: 11,
        is_new: true,
        quizz: {
          id: 1,
          title: "Which is your favorite brand ?",
          amount: 1,
          small_picture_url: "http://xxx.com/xxxxxx/favorite_brand_quiz.png",
          bonus: 1,
        },
        video: {
          id: 1,
          title: "Coca Cola Video",
          amount: 2,
          small_picture_url: "http://xxx.com/xxxxxx/coca_cola_video.png",
          bonus: 2,
        },
      },
      bonus: 0,
      is_favor: true,
      comment: "string",
      receipt_id: 0,
      retailer_id: 1,
      status: 0,
    },
    {
      id: 0,
      type: "string",
      expired_at: "2021-10-22T11:05:34.613Z",
      amount: 20,
      offer: {
        id: 1,
        ref: "COUP01",
        discount_value: 5.5,
        title: "Coca Cola Zero",
        subtitle: "Pack of 6 bottles",
        picture_url: "http://xxx.com/xxxxxx/coca_cola.png",
        best: true,
        brand_id: 10,
        promocode_value: 3,
        points: 11,
        is_new: true,
        quizz: {
          id: 1,
          title: "Which is your favorite brand ?",
          amount: 1,
          small_picture_url: "http://xxx.com/xxxxxx/favorite_brand_quiz.png",
          bonus: 1,
        },
        video: {
          id: 1,
          title: "Coca Cola Video",
          amount: 2,
          small_picture_url: "http://xxx.com/xxxxxx/coca_cola_video.png",
          bonus: 2,
        },
      },
      bonus: 0,
      is_favor: true,
      comment: "string",
      receipt_id: 0,
      retailer_id: 1,
      status: 0,
    },
    {
      id: 0,
      type: "string",
      expired_at: "2021-10-22T11:05:34.613Z",
      amount: 30,
      offer: {
        id: 1,
        ref: "COUP01",
        discount_value: 5.5,
        title: "Coca Cola Zero",
        subtitle: "Pack of 6 bottles",
        picture_url: "http://xxx.com/xxxxxx/coca_cola.png",
        best: true,
        brand_id: 10,
        promocode_value: 3,
        points: 11,
        is_new: true,
        quizz: {
          id: 1,
          title: "Which is your favorite brand ?",
          amount: 1,
          small_picture_url: "http://xxx.com/xxxxxx/favorite_brand_quiz.png",
          bonus: 1,
        },
        video: {
          id: 1,
          title: "Coca Cola Video",
          amount: 2,
          small_picture_url: "http://xxx.com/xxxxxx/coca_cola_video.png",
          bonus: 2,
        },
      },
      bonus: 0,
      is_favor: true,
      comment: "string",
      receipt_id: 0,
      retailer_id: 1,
      status: 0,
    },
    {
      id: 0,
      type: "string",
      expired_at: "2021-10-22T11:05:34.613Z",
      amount: 40,
      offer: {
        id: 1,
        ref: "COUP01",
        discount_value: 5.5,
        title: "Coca Cola Zero",
        subtitle: "Pack of 6 bottles",
        picture_url: "http://xxx.com/xxxxxx/coca_cola.png",
        best: true,
        brand_id: 10,
        promocode_value: 3,
        points: 11,
        is_new: true,
        quizz: {
          id: 1,
          title: "Which is your favorite brand ?",
          amount: 1,
          small_picture_url: "http://xxx.com/xxxxxx/favorite_brand_quiz.png",
          bonus: 1,
        },
        video: {
          id: 1,
          title: "Coca Cola Video",
          amount: 2,
          small_picture_url: "http://xxx.com/xxxxxx/coca_cola_video.png",
          bonus: 2,
        },
      },
      bonus: 0,
      is_favor: true,
      comment: "string",
      receipt_id: 0,
      retailer_id: 1,
      status: 0,
    },
    {
      id: 0,
      type: "string",
      expired_at: "2021-10-22T11:05:34.613Z",
      amount: 50,
      offer: {
        id: 1,
        ref: "COUP01",
        discount_value: 5.5,
        title: "Coca Cola Zero",
        subtitle: "Pack of 6 bottles",
        picture_url: "http://xxx.com/xxxxxx/coca_cola.png",
        best: true,
        brand_id: 10,
        promocode_value: 3,
        points: 11,
        is_new: true,
        quizz: {
          id: 1,
          title: "Which is your favorite brand ?",
          amount: 1,
          small_picture_url: "http://xxx.com/xxxxxx/favorite_brand_quiz.png",
          bonus: 1,
        },
        video: {
          id: 1,
          title: "Coca Cola Video",
          amount: 2,
          small_picture_url: "http://xxx.com/xxxxxx/coca_cola_video.png",
          bonus: 2,
        },
      },
      bonus: 0,
      is_favor: true,
      comment: "string",
      receipt_id: 0,
      retailer_id: 1,
      status: 0,
    },
    {
      id: 0,
      type: "string",
      expired_at: "2021-10-22T11:05:34.613Z",
      amount: 60,
      offer: {
        id: 1,
        ref: "COUP01",
        discount_value: 5.5,
        title: "Coca Cola Zero",
        subtitle: "Pack of 6 bottles",
        picture_url: "http://xxx.com/xxxxxx/coca_cola.png",
        best: true,
        brand_id: 10,
        promocode_value: 3,
        points: 11,
        is_new: true,
        quizz: {
          id: 1,
          title: "Which is your favorite brand ?",
          amount: 1,
          small_picture_url: "http://xxx.com/xxxxxx/favorite_brand_quiz.png",
          bonus: 1,
        },
        video: {
          id: 1,
          title: "Coca Cola Video",
          amount: 2,
          small_picture_url: "http://xxx.com/xxxxxx/coca_cola_video.png",
          bonus: 2,
        },
      },
      bonus: 0,
      is_favor: true,
      comment: "string",
      receipt_id: 0,
      retailer_id: 45,
      status: 0,
    },
    {
      id: 0,
      type: "string",
      expired_at: "2021-10-22T11:05:34.613Z",
      amount: 70,
      offer: {
        id: 1,
        ref: "COUP01",
        discount_value: 5.5,
        title: "Coca Cola Zero",
        subtitle: "Pack of 6 bottles",
        picture_url: "http://xxx.com/xxxxxx/coca_cola.png",
        best: true,
        brand_id: 10,
        promocode_value: 3,
        points: 11,
        is_new: true,
        quizz: {
          id: 1,
          title: "Which is your favorite brand ?",
          amount: 1,
          small_picture_url: "http://xxx.com/xxxxxx/favorite_brand_quiz.png",
          bonus: 1,
        },
        video: {
          id: 1,
          title: "Coca Cola Video",
          amount: 2,
          small_picture_url: "http://xxx.com/xxxxxx/coca_cola_video.png",
          bonus: 2,
        },
      },
      bonus: 0,
      is_favor: true,
      comment: "string",
      receipt_id: 0,
      retailer_id: 45,
      status: 0,
    },
    {
      id: 0,
      type: "string",
      expired_at: "2021-10-22T11:05:34.613Z",
      amount: 80,
      offer: {
        id: 1,
        ref: "COUP01",
        discount_value: 5.5,
        title: "Coca Cola Zero",
        subtitle: "Pack of 6 bottles",
        picture_url: "http://xxx.com/xxxxxx/coca_cola.png",
        best: true,
        brand_id: 10,
        promocode_value: 3,
        points: 11,
        is_new: true,
        quizz: {
          id: 1,
          title: "Which is your favorite brand ?",
          amount: 1,
          small_picture_url: "http://xxx.com/xxxxxx/favorite_brand_quiz.png",
          bonus: 1,
        },
        video: {
          id: 1,
          title: "Coca Cola Video",
          amount: 2,
          small_picture_url: "http://xxx.com/xxxxxx/coca_cola_video.png",
          bonus: 2,
        },
      },
      bonus: 0,
      is_favor: true,
      comment: "string",
      receipt_id: 0,
      retailer_id: 45,
      status: 0,
    },
    {
      id: 0,
      type: "string",
      expired_at: "2021-10-22T11:05:34.613Z",
      amount: 90,
      offer: {
        id: 1,
        ref: "COUP01",
        discount_value: 5.5,
        title: "Coca Cola Zero",
        subtitle: "Pack of 6 bottles",
        picture_url: "http://xxx.com/xxxxxx/coca_cola.png",
        best: true,
        brand_id: 10,
        promocode_value: 3,
        points: 11,
        is_new: true,
        quizz: {
          id: 1,
          title: "Which is your favorite brand ?",
          amount: 1,
          small_picture_url: "http://xxx.com/xxxxxx/favorite_brand_quiz.png",
          bonus: 1,
        },
        video: {
          id: 1,
          title: "Coca Cola Video",
          amount: 2,
          small_picture_url: "http://xxx.com/xxxxxx/coca_cola_video.png",
          bonus: 2,
        },
      },
      bonus: 0,
      is_favor: true,
      comment: "string",
      receipt_id: 0,
      retailer_id: 45,
      status: 0,
    },
    {
      id: 0,
      type: "string",
      expired_at: "2021-10-22T11:05:34.613Z",
      amount: 100,
      offer: {
        id: 1,
        ref: "COUP01",
        discount_value: 5.5,
        title: "Coca Cola Zero",
        subtitle: "Pack of 6 bottles",
        picture_url: "http://xxx.com/xxxxxx/coca_cola.png",
        best: true,
        brand_id: 10,
        promocode_value: 3,
        points: 11,
        is_new: true,
        quizz: {
          id: 1,
          title: "Which is your favorite brand ?",
          amount: 1,
          small_picture_url: "http://xxx.com/xxxxxx/favorite_brand_quiz.png",
          bonus: 1,
        },
        video: {
          id: 1,
          title: "Coca Cola Video",
          amount: 2,
          small_picture_url: "http://xxx.com/xxxxxx/coca_cola_video.png",
          bonus: 2,
        },
      },
      bonus: 0,
      is_favor: true,
      comment: "string",
      receipt_id: 0,
      retailer_id: 45,
      status: 0,
    },
  ],
};
