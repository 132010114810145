import {applyMiddleware, createStore} from "redux";
import { composeWithDevTools } from 'redux-devtools-extension';

import rootReducer from "./rootReducer";
import thunk from "redux-thunk"
import {useSelector as rawUseSelector, TypedUseSelectorHook} from "react-redux";
const middleware = {
    thunk
}
const store = createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(thunk))
);
export type RootState = ReturnType<typeof store.getState>;

export const useSelector: TypedUseSelectorHook<RootState> = rawUseSelector;
// store.subscribe(()=>{
//     console.log(store.getState())
// })
export default store;
