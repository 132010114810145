import { PencilIcon } from "@heroicons/react/solid";
import { LockClosedIcon, MailIcon, UserIcon } from "@heroicons/react/outline";
import { IonContent, IonPage } from "@ionic/react";

export const AccountScreen = () => {
  return (
    <IonPage className="pt-16">
      <IonContent>
        <div className="m-4 mt-6 p-4 shadow-custom rounded-md">
          <div>My Card Registration</div>
          <div className="flex items-center justify-between pt-2">
            <div className="text-2xl font-bold">455 098 289 178</div>
            <div className="bg-okuwa_blue rounded-full h-7 w-7 flex items-center justify-center">
              <PencilIcon className="h-5 w-5 text-white" />
            </div>
          </div>
        </div>

        <div className="m-4 p-4 shadow-custom rounded-md">
          <div className="flex items-center justify-between">
            <div className="">お気に入り登録店舗選択</div>
            <div className="bg-okuwa_blue rounded-full h-7 w-7 flex items-center justify-center">
              <PencilIcon className="h-5 w-5 text-white" />
            </div>
          </div>
        </div>

        <div className="m-4 p-4 shadow-custom rounded-md">
          <div className="flex items-center">
            <UserIcon className="h-5 w-5" />
            <div className="flex-1 pl-3">John Doe</div>
            <div className="bg-okuwa_blue rounded-full h-7 w-7 flex items-center justify-center">
              <PencilIcon className="h-5 w-5 text-white" />
            </div>
          </div>
          <div className="flex mt-6 items-center">
            <MailIcon className="h-5 w-5" />
            <div className="flex-1 pl-3">johndoe@email.com</div>
            <div className="bg-okuwa_blue rounded-full h-7 w-7 flex items-center justify-center">
              <PencilIcon className="h-5 w-5 text-white" />
            </div>
          </div>
          <div className="flex mt-6 items-center">
            <LockClosedIcon className="h-5 w-5" />
            <div className="flex-1 pl-3">**************</div>
            <div className="bg-okuwa_blue rounded-full h-7 w-7 flex items-center justify-center">
              <PencilIcon className="h-5 w-5 text-white" />
            </div>
          </div>
        </div>

        <div className="m-4 p-4 shadow-custom rounded-md">
          <div className="flex items-center justify-between">
            <div className="tracking-wide">FAQ</div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};
