import {
    SET_BASKET_LIST, SET_BASKET_LOADING,
    SET_OFFER_LIST, SET_OFFERS_ADDING,
    SET_OFFERS_LOADING,
} from "./offer-list.types";

import {SET_CATEGORIES} from "../categories/categories.types";
import MembersService from "../../services/members.service";
import {SET_APP_LOADING} from "../global/global.types";
const CURRENT_ENV = process.env.REACT_APP_ENV;
export const setOfferList = (offers) => {
return {
  type: SET_OFFER_LIST,
  payload: offers,
};
};

export const setOfferListLoading = (loading) => {
return {
  type: SET_OFFERS_LOADING,
  payload: loading,
};
};

export const setBasketLoading = (loading) => {
return {
  type: SET_BASKET_LOADING,
  payload: loading,
};
};

export const setOfferAdding = (loading) => {
return {
  type: SET_OFFERS_ADDING,
  payload: loading,
};
};

export const fetchOffers = (catId) => {
  return (dispatch, getState) => {
      const {stores, categories, cards} = getState()
      let storeId = stores && stores.selectedStore && stores.selectedStore.id
      MembersService.getCoupons(stores.selectedStore ? storeId : 0,
          catId? catId : categories.selectedCategory?categories.selectedCategory.id:1,
          cards && cards.holders && cards.holders.length>0 ? cards.holders[0].retailer_id : CURRENT_ENV === 'prod' ? 19 : 59
      )
          .then((responseJson) => {
              let offers = []
              let bestOffers = [];
              let allOffers = responseJson.data.items;
              responseJson.data.items.forEach((offer)=>{
                  if(offer.hasOwnProperty('default_target') && offer.default_target === false){
                  // if(offer.hasOwnProperty('id') && offer.id%2 == 0 ){
                      bestOffers.push(offer)
                  } else {
                      offers.push(offer);
                  }
              })
            dispatch({
              type: SET_OFFER_LIST,
              // payload: responseJson.data.items,
              payload: [offers,bestOffers, responseJson.data.items]
            });
            dispatch({
                type: SET_APP_LOADING,
                payload: false,
            });
          })
          .catch((error) => {
            console.log(error);
          });
};
};

export const fetchOffersForHomePage = (catId) => {
return dispatch => {
  MembersService.getCouponsForHomePage(catId)
      .then((responseJson) => {
        dispatch({
          type: SET_OFFER_LIST,
          payload: responseJson.data.items.slice(0, 6)
        });
      })
      .catch((error) => {
        console.log(error);
      });
};
}

export const fetchCategories = () => {
// console.log("fetchCategories");
return dispatch => {
  // console.log("fetchCategories dispatch");
  MembersService.getCoupons(0, 1)
      .then((responseJson) => {
        dispatch({
          type: SET_OFFER_LIST,
          payload: responseJson.data.items.slice(0,6)
        });
      })
      .catch((error) => {
        console.log(error);
      });
  MembersService.getCategories()
  .then((responseJson) => {
    if (responseJson.data.length) {
      const allCategories = [];
      allCategories.push({
        id: 1,
        name: "全て",
        tag: "All",
        precedence: 1,
      });

      dispatch({
        type: SET_CATEGORIES,
        payload: allCategories.concat(responseJson.data)
      });

    }
  })
  .catch((error) => {
    console.log(error);
  });
};
};

export const fetchCategoriesForHomePage = () => {
  // console.log("fetchCategoriesForHomePage");
  return dispatch => {
      // console.log("fetchCategoriesForHomePage dispatch");

      MembersService.getCategoriesForHomePage()
          .then((responseJson) => {
              if (responseJson.data.length) {
                const allCategories = [];
                  let categories = allCategories.concat(responseJson.data)
                  categories.sort(function (a, b) {
                    if (a.precedence > b.precedence) {
                      return 1;
                    } else if (a.precedence < b.precedence) {
                      return -1;
                    } else {
                      return 0;
                    }
                  });
                  categories.unshift({
                    id: 1,
                    name: "全て",
                    tag: "All",
                    precedence: 0,
                  });
                  // const allCategories = [];
                  // allCategories.push({
                  //     id: 1,
                  //     name: "全て",
                  //     tag: "All",
                  //     precedence: 0,
                  // });

                  dispatch({
                      type: SET_CATEGORIES,
                      payload: categories
                  });

              }
          })
          .catch((error) => {
              console.log(error);
          });
  };
};
// MembersService.getCategories()
//     .then((responseJson) => {
//       if (responseJson.data.length) {
//         const allCategories = [];
//         allCategories.push({
//           id: 1,
//           name: "全て",
//           tag: "All",
//           precedence: 1,
//         });
//         props.setCategories(allCategories.concat(responseJson.data));
//         setCategoriesLoaded(true);
//         fetchFavoriteStores();
//       }
//     })
//     .catch((error) => {
//       setCategoriesLoaded(true);
//       console.log(error);
//     });
export const setBasketList = (offers) => {
return {
  type: SET_BASKET_LIST,
  payload: offers,
};
};
export const fetchBasket = () => {
// console.log("fetchBasket");
return (dispatch, getState) => {
  // console.log("fetchRetailers dispatch");
  const {stores, cards} = getState()
  // console.log(stores)

  MembersService.getBasket(
      stores.selectedStore ? stores.selectedStore.id : 0,
      cards && cards.holders && cards.holders.length>0 ? cards.holders[0].retailer_id: CURRENT_ENV === 'prod' ? 19 : 59
  )
      .then((responseJson) => {
              dispatch({
                type: SET_BASKET_LIST,
                payload: responseJson.data
              });
      })
      .catch((error) => {
        console.log(error);
      });
};
};

export const addCouponToBasket = (offerId, store) => {
  return (dispatch, getState) => {
      dispatch({
          type: SET_OFFERS_ADDING,
          payload: true
      });
      const {stores, categories, cards} = getState()
      MembersService.addCouponToBasket(offerId, store)
          .then((responseJson) => {
              dispatch(fetchOffers());
              dispatch(fetchBasket());
              dispatch({
                  type: SET_OFFERS_ADDING,
                  payload: false
              });
          })
          .catch((error) => {
              console.log(error);
              dispatch({
                  type: SET_OFFERS_ADDING,
                  payload: false
              });
          });
  };
}
