import { CreditCardIcon } from "@heroicons/react/outline"
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { fetchHolderCards } from "../../../redux/holder-cards/holder-cards.actions";
import {useEffect} from "react";

const mapStateToProps = (state: any) => {
    return {
        state: state,
        isAppLoading: state.global.isAppLoading,
        cards: state.cards.holders,
        cardsLoading: state.cards.loading,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        fetchHolderCards: () => dispatch(fetchHolderCards()),
    };
};
interface Props {
    state: any;
    isAppLoading: boolean;
    fetchHolderCards: Function;
    cards: any[];
    cardsLoading: boolean;
}

export const HolderCardComponent = (props: Props) => {
    const { t } = useTranslation();
    useEffect(() => {
        console.log('cards');
        props.fetchHolderCards()
    }, []);
    return (
        <div className="px-6 py-8 dark:text-black">
            <div className="text-sm">{t("Ocard_no")}</div>
            <div className="flex items-center pt-2">
                <CreditCardIcon className="w-6 h-6 text-gray-500" />
                {props.cards.length > 0 &&
                    <div className="pl-2 text-2xl">
                        {props.cards[0].ref.substr(1, 4).replace(new RegExp("\\d", "g"), "X")}-
                        {props.cards[0].ref.substr(5, 4).replace(new RegExp("\\d", "g"), "X")}-
                        {props.cards[0].ref.substr(9, 4).replace(new RegExp("\\d", "g"), "X")}-
                        {props.cards[0].ref.substr(-4)}
                    </div>
                }
                {/* <div className="pl-2 text-2xl">{cardNumber}</div> */}
            </div>
        </div>
    )
}
export const HolderCard = connect(mapStateToProps, mapDispatchToProps)(HolderCardComponent);