import { IonContent, IonPage } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

const menuItems = [
  {
    name: "How to use the App",
    link: "/info/how-to-use-app",
  },
  {
    name: "FAQ",
    link: "/info/faq",
  },
  {
    name: "Inquiry",
    link: "/info/inquiry",
  },
  {
    name: "Terms of service",
    link: "/info/terms-service",
  },
  {
    name: "Privacy policy",
    link: "/info/privacy",
  },
];

export const Info = () => {
  const { t } = useTranslation();
  const history = useHistory();
  return (
    <IonPage className="pt-16 ">
      <IonContent>
        <div className="h-full m-4 shadow-custom p-6 dark:m-0 rounded-md dark:bg-white dark:text-black">
          <div className="text mb-8">{t("About Information")}</div>
          {menuItems.map((menuItem, index) => (
            <div key={`${index}`} onClick={() => {
              history.push(menuItem.link);
            }} className="text mt-5 underline">{t(menuItem.name)}</div>
          ))}
        </div>
      </IonContent>
    </IonPage>
  );
};
