import {FETCH_HOLDER_CARDS, SET_HOLDER_CARDS} from "./holder-cards.types";
import DataService from "../../services/data.service";
import {SET_RETAILER_LIST} from "../retailers/retailers.types";
import MembersService from "../../services/members.service";

export const setHolderCards = (data) => {
  return {
    type: SET_HOLDER_CARDS,
    payload: data,
  };
};


export const fetchHolderCards = () => {
  return dispatch => {
      MembersService.getHolders()
        .then(res => {
          const holders = res.data;
          dispatch({
            type: SET_HOLDER_CARDS,
            payload: holders
          });
        })
  };
};
