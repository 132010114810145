import { IonContent, IonPage } from "@ionic/react";
import { useTranslation } from "react-i18next";

export const Privacy = () => {
  const { t } = useTranslation();
  return (
    <IonPage className="pt-16">
      <IonContent>
        {/* <BackToInfo /> */}
        <div className="dark:bg-white  pt-5 p-4 text-sm h-full" style={{ lineHeight: 2, color: "#1F1F1F" }}>
          <div className="text-xl font-bold">個人情報保護方針</div>
          <div className="mt-12 text">クータッチのサービス運営会社であるカタリナマーケティングジャパン株式会社の個人情報保護方針は以下のリンクよりご確認をお願いいたします。</div>
          <div
            className="underline-offset-4 text-blue-700 mt-4"
            style={{
              textDecorationLine: "underline",
              textUnderlineOffset: '3px'
            }}
            onClick={() => {
              window.location.href =
                "https://www.catalinamarketing.co.jp/privacy-policy/#houshin";
              // liff.closeWindow();
            }}
          >
              https://www.catalinamarketing.co.jp/privacy-policy/#houshin
          </div>

        </div>
      </IonContent>
    </IonPage>
  );
};
