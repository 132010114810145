import {
    SET_RETAILER_LIST,
    SELECT_RETAILER_FOR_ACCOUNT,
    SET_RETAILERS_LOADING,
    SET_RETAILER,
    SET_PREFECTURE,
    SET_PREFECTURE_LIST,
    SET_CITY_LIST,
    SET_CITY,
    SET_AREA_LIST,
    SET_AREA,
    SET_STORES_LIST,
    SET_STORE,
    ADD_FAVORITE_STORE, ADD_FAVORITE_STORE_ERROR, RESET_ERROR, RESET_HIERARCHY, REMOVE_FAVORITE_STORE, REMOVE_FAVORITE_STORE_ERROR, SET_ALL_STORES_LIST
} from "./retailers.types";


import {SET_HOLDER_CARDS} from "../holder-cards/holder-cards.types";
import {SET_FAVORITE_STORES} from "../stores/stores.types";
import DataService from "../../services/data.service";
import MembersService from "../../services/members.service";

export const setRetailers = (data) => {
  return {
    type: SET_RETAILER_LIST,
    payload: data,
  };
};

export const selectRetailerForAccount = (data) => {
  return {
    type: SELECT_RETAILER_FOR_ACCOUNT,
    payload: data,
  };
};

export const fetchRetailers = () => {
  return dispatch => {
    DataService.getRetailers()
        .then(res => {
          const retailers = res.data;
          dispatch({
            type: SET_RETAILER_LIST,
            payload: retailers
          });
        })
  };
};

export const setRetailer = (retailer) => {
  return dispatch => {
    dispatch({
      type: SET_RETAILER,
      payload: retailer
    });
  };
};

export const resetHierarchy = () => {
    return dispatch => {
        dispatch({
            type: RESET_HIERARCHY,
            payload: {}
        });
    };
};

export const fetchPrefectures = (retailer) => {
  return dispatch => {
    DataService.getPrefectures(retailer)
        .then(res => {
          const prefectures = res.data.items;
          dispatch({
            type: SET_PREFECTURE_LIST,
            payload: prefectures
          });
        })
  };
};

export const setPrefecture = (prefecture) => {
  return dispatch => {
    dispatch({
      type: SET_PREFECTURE,
      payload: prefecture
    });
  };
};

export const fetchCities = (retailer, prefecture) => {
  return dispatch => {
    DataService.getCities(retailer, prefecture)
        .then(res => {
          const cities = res.data.items;
          dispatch({
            type: SET_CITY_LIST,
            payload: cities
          });
        })
  };
};

export const setCity = (city) => {
  return dispatch => {
    dispatch({
      type: SET_CITY,
      payload: city
    });
  };
};

export const fetchAreas = (retailer, prefecture, city) => {
  return dispatch => {
    DataService.getAreas(retailer, prefecture, city)
        .then(res => {
          const areas = res.data.items;
          dispatch({
            type: SET_AREA_LIST,
            payload: areas
          });
        })
  };
};

export const fetchStores = (retailer, prefecture, city) => {
  return dispatch => {
    DataService.getStores(retailer, prefecture, city)
        .then(res => {
          const stores = res.data.items;
          dispatch({
            type: SET_STORES_LIST,
            payload: stores
          });
        })
      MembersService.getHolders()
          .then((responseJson) => {
              dispatch( {
                  type: SET_HOLDER_CARDS,
                  payload: responseJson.data,
              });
          })
          .catch((error) => {
              console.log(error);
          });
  };
};

export const fetchAllStoresNoFilter = (retailer) => {
  return dispatch => {
    DataService.getAllStoresNoFilter(retailer)
        .then(res => {
          const stores = res.data.items;
          dispatch({
            type: SET_ALL_STORES_LIST,
            payload: stores
          });
        })
  };
};

export const fetchStoreToSelect = (retailer, prefecture, city) => {
    return dispatch => {
        // SET_RETAILERS_LOADING
        dispatch({
            type: SET_RETAILERS_LOADING,
            payload: true
        });
        DataService.getStores(retailer, prefecture, city)
            .then(res => {
                let stores = res.data.items;
                MembersService.getFavoriteStores().then((favresponse)=>{
                    let favStoreIds = favresponse.data.map((f)=> f.id);
                    let filteredStores = [];
                    stores.forEach((store)=>{
                        if(favStoreIds.includes(store.id)){
                            filteredStores.push({...store, isFav:true});
                        } else {
                            filteredStores.push({...store, isFav:false});
                        }
                    })
                    dispatch({
                        type: SET_STORES_LIST,
                        payload: filteredStores
                    });
                    dispatch({
                        type: SET_FAVORITE_STORES,
                        payload: favresponse.data,
                    });
                });

            })
        MembersService.getHolders()
            .then((responseJson) => {
                dispatch( {
                    type: SET_HOLDER_CARDS,
                    payload: responseJson.data,
                });
            })
            .catch((error) => {
                console.log(error);
            });
    };
};

export const setStore = (store) => {
  return dispatch => {
    dispatch({
      type: SET_STORE,
      payload: store
    });
  };
};

export const addToFavStores = (storeId) => {
  return dispatch => {
    
    MembersService.addStoreToFavs(storeId, null)
        .then((response) => {
            dispatch({
                type: ADD_FAVORITE_STORE,
                payload: response.data
            });
        })
        .catch((error) => {
          if (error.response && error.response.data) {
              dispatch({
                  type: ADD_FAVORITE_STORE_ERROR,
                  payload: error.response.data.error
              });
          }
        });
  };
};

export const removeFromFavStores = (storeId) => {
  return dispatch => {
    MembersService.removeStoreFromFavs(storeId)
        .then((response) => {
            dispatch({
                type: REMOVE_FAVORITE_STORE,
                payload: response.data
            });
        })
        .catch((error) => {
          if (error.response && error.response.data) {
              dispatch({
                  type: REMOVE_FAVORITE_STORE_ERROR,
                  payload: error.response.data.error
              });
          }
        });
  };
};

export const resetError = () => {
    // console.log("resetError");
    return dispatch => {
        // console.log("resetError dispatch");
        dispatch({
            type: RESET_ERROR,
            payload: {}
        });
    };
};

export const setArea = (area) => {
  return dispatch => {
    dispatch({
      type: SET_AREA,
      payload: area
    });
  };
};


export const setRetailersLoading = (data) => {
  return {
    type: SET_RETAILERS_LOADING,
    payload: data,
  };
}

export const fetchFavoriteStores = () => {
    return dispatch => {
        MembersService.getFavoriteStores().then((favresponse)=>{
            dispatch({
                type: SET_FAVORITE_STORES,
                payload: favresponse.data,
            });
        })
    };
};
