import { IonButton, IonCheckbox, IonItem, IonLabel } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import AuthService from "../../services/auth.service";
import MembersService from "../../services/members.service";
import { ScrollpageWithHeader } from "../../common/scrollpage-with-header/scrollpage-with-header";
import { TextField } from "../../components/text-field/text-field";
import { ConfirmModal } from "../../components/confirm-modal/confirm-modal";
import DataService from "../../services/data.service";
import {AppSettings} from "../../AppSettings";
var CryptoJS = require("crypto-js");
// const CURRENT_ENV=process.env.REACT_APP_ENV as string;
const CURRENT_ENV = process.env.REACT_APP_ENV as string;

declare global {
  interface Window {
    dataLayer: Array<Object>;
  }
}
function useQueryParams() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}
const formatCreditCardNumber = (value: any) => {
  if (!value) {
    return value;
  }
  function clearNumber(value = "") {
    return value.replace(/\D+/g, "");
  }
  const clearValue = clearNumber(value);
  let nextValue;
  nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
    4,
    8
  )} ${clearValue.slice(8, 12)} ${clearValue.slice(12, 19)}`;
  // console.log("cardnumber value is:" + nextValue.trim().replaceAll(" ", "") + "@catalina.com");
  return nextValue.trim().replaceAll(" ", "");
};
export const Terms2Page = () => {
  const history = useHistory();
  let queryParams = useQueryParams();
  const [checked, setChecked] = useState(false);
  const [email, setEmail] = useState<string>("6346-2387-2387-1125");
  const cardNo = React.useRef(queryParams.get('c')!);
  const [password,] = useState<string>(AppSettings.CATALINA_PASSWORD);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [openErrorModel, setOpenErrorModel] = useState<boolean>(false);
  const [isLoading, setLoading] = useState(false);


  // const handlePatch = () => {
  //   MembersService.patchUser({ email })
  //     .then((res) => {
  //       console.log("user patch update res ", res);
  //       history.push("/2");
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       setErrorMessage(err.response.data.error);
  //       setOpenErrorModel(true);
  //     });
  // };

  // const formatCreditCardNumber = (value: any) => {
  //   if (!value) {
  //     return value;
  //   }
  //   function clearNumber(value = "") {
  //     return value.replace(/\D+/g, "");
  //   }
  //   const clearValue = clearNumber(value);
  //   let nextValue;
  //   nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
  //     4,
  //     8
  //   )} ${clearValue.slice(8, 12)} ${clearValue.slice(12, 19)}`;
  //   console.log("cardnumber value is:" + nextValue.trim().replaceAll(" ", "") + "@catalina.com");
  //   return nextValue.trim().replaceAll(" ", "");
  // };
  useEffect(() => {
    cardNo.current = queryParams.get('c')!;
  }, []);

  const handleSignup = () => {
    setLoading(true);
    // AuthService.signup("francisco.rojas@catalina.com", password, [{
    //   ref: cardNo.current!,
    //   retailer_id: 59
    // }])
    // AuthService.signup(formatCreditCardNumber(email), formatCreditCardNumber(email) + "@catalina.com", password)
    // .then((response) => {
    // setLoading(true);
    // AuthService.socialLogin(liff.getAccessToken(), lineUser.userId)
    AuthService.login(AppSettings.CATALINA_TEST_ACCOUNT, password)
      .then(async (response) => {
        localStorage.setItem(
          "user-data",
          JSON.stringify(response.data)
        );
        if (queryParams.get('s') !== null && /^\d+$/.test(queryParams.get('s')!)) {
          const mappedStoreId = DataService.getStoreMapping(queryParams.get('s'));
          if (mappedStoreId) {
            await MembersService.addStoreToFavs(mappedStoreId, null);
          }
        }
        return MembersService.getFavoriteStores();
      })
      .then((response) => {
        setLoading(false);
        history.push("/home");
      })
      .catch((err) => {
        setLoading(false);
        setErrorMessage(err.response.data.message ? err.response.data.message : err.response.data.error);
        setOpenErrorModel(true);
      });
    // }
    // )
    // .catch((err) => {
    //   setLoading(false);
    //   setErrorMessage(err.response.data.error);
    //   setOpenErrorModel(true);
    // });
  }
  return (
    <ScrollpageWithHeader title="メルアドレスを登録" id="terms">
      <div className="px-6 mt-6">
        {openErrorModel && (
          <div className="text-red-600 text-xs -mt-3 mb-10">{errorMessage}</div>
        )}
        <IonLabel className="account-editing">メールアドレス</IonLabel>
        {/*<TextField*/}
        {/*  placeholder="メールアドレスを入力してください"*/}
        {/*  value={email}*/}
        {/*  onChange={changeEmailAndCard}*/}
        {/*  type="text"*/}
        {/*/>*/}
        {/* {email && !validateEmail(email) && (
          <div className="text-red-600 text-xs -mt-3 mb-10">
            メールアドレスを正しく入力してください。
          </div>
        )} */}

        <div className="mt-16"></div>
        <div className="text">利用規約</div>
        <div className="text-sm text-gray mt-4">
          本利用規約は、カタリナマーケティングジャパン株式会社(以下｢当社｣といいます)が運営するクーポン値引きをカードに還元するサービス「カタリナ」をご利用頂くに当たっての注意事項を規定したものです。ご利用に先立ち、本利用規約をよくお読み頂きますようお願い申し上げます。ユーザーは、本サービス（第2条で定義します）を利用することにより、本利用規約の内容に同意したものとさせて頂きます。
          利用資格
          本サービス内のお酒のクーポンをご利用頂けるのは、20歳以上の方に限られます。
          用語の定義について「本サービス」とは、利用者が当社アプリまたは当社サイト上でクーポンを取得することができる当社のサービスおよびこれに付随して当社アプリまたは当社サイトにおいて提供する全てのサービスをいいます。
        </div>
      </div>
      <div>
      <IonItem lines="none" className="mt-6 flex items-center">
        <IonCheckbox
          checked={checked}
          onIonChange={(e) => setChecked(e.detail.checked)}
        />
        <IonLabel>利用規約に同意する</IonLabel>
      </IonItem>
      </div>
      <div className="px-4 my-6">
        <IonButton
          disabled={
            !email || !checked
          }
          onClick={handleSignup}
          expand="block"
          color="light"
        >
          送信する
        </IonButton>
      </div>
    </ScrollpageWithHeader>
  );
};
