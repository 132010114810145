import {
  REMOVE_EVENT,
  SET_APP_LOADING, SET_EVENT, SET_FIRST_LOAD
} from "./global.types";

const INITIAL_STATE = {
  isAppLoading: true,
  event: null,
  first_load:true,
  user: null,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {

    case SET_APP_LOADING:
      return {
        ...state,
        isAppLoading: action.payload,
      };
    case SET_EVENT:
      return {
        ...state,
        event: action.payload,
      };
    case REMOVE_EVENT:
      return {
        ...state,
        event: action.payload,
      };
    case SET_FIRST_LOAD:
      return {
        ...state,
        first_load: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
