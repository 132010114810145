import {
  SET_BASKET_LIST,
  SET_OFFER_LIST, SET_OFFERS_ADDING,
  SET_OFFERS_LOADING,
  SET_BASKET_LOADING
} from "./offer-list.types";

const INITIAL_STATE = {
  basketPoints: 0,
  offers: [],
  bestOffers: [],
  nonBestOffers: [],
  offersLoading: false,
  basket: [],
  allBasket: [],
  isAddingOffer: false,
  basketReLoaded: true
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_OFFER_LIST:
      return {
        ...state,
        offers: action.payload[2],
        bestOffers: action.payload[1],
        nonBestOffers: action.payload[0],
        offersLoading: false
      };

    case SET_OFFERS_LOADING:
      return {
        ...state,
        offersLoading: action.payload,
      };
    case SET_OFFERS_ADDING:
      return {
        ...state,
        isAddingOffer: action.payload,
      };
    case SET_BASKET_LIST:
      let sumOfPoints = 0;
      let allBasket = action.payload;
      let offers = action.payload.filter(offer => offer.status !== 7);
      offers.map((reward) => {
        sumOfPoints = sumOfPoints + reward.offer.discount_value;
      });

      return {
        ...state,
        basketPoints: sumOfPoints,
        basket: offers,
        allBasket: allBasket,
        basketReLoaded: true
      };
    case SET_BASKET_LOADING:
      return {
        ...state,
        basketReLoaded: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
