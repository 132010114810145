import { ChevronDownIcon } from "@heroicons/react/outline";
import { motion, AnimatePresence } from "framer-motion";
import { useState } from "react";
import {classNames} from "../../header/header";

export const PointsList = (props: any) => {
  let datePoints = props.monthlyData as any;
  const [accordion, setAccordion] = useState<{
    [key: string]: boolean;
  }>({});

  let status:any = {
    'paid': '付与完了',
    'refused': '不承認',
    'pending': '保留中',
    'to_be_paid': '処理中',
    'validated': '検証済み',

  }
  return (
    <motion.div className="py-2 bg-gray-100">
      {datePoints.map((dp: any, index: number) => {
        const array = Array.from(dp.dates);
        array.sort((a:any, b:any) => {
          // Convert the date strings to Date objects
          let dateA = new Date(a);
          let dateB = new Date(b);
          // Subtract the dates to get a value that is either negative, positive, or zero
          return dateB.valueOf() - dateA.valueOf();
        });

        return (
            <div
                key={index}
                className="bg-white rounded-md shadow-custom m-4 px-4 py-3"
            >
              <div key={`${index}`} className="flex items-center dark:text-black ">
                <div className="flex-1">{dp.key}</div>
                <div className="pr-3 text-xl font-semibold">{dp.total}P</div>
                <motion.div
                    className="p-1 bg-gray-100 rounded-full"
                    onClick={() => {
                      setAccordion({
                        ...accordion,
                        [index]: !accordion[index],
                      });
                    }}
                >
                  <ChevronDownIcon className="w-4 h-4" />
                </motion.div>
              </div>
              <AnimatePresence>
                {accordion[index] && (
                    <div>
                      <div className="h-3"></div>
                      <motion.div
                          className="border-t divide-y"
                          initial={{ opacity: 0, height: 0 }}
                          transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
                          animate={{
                            opacity: 1,
                            height: "auto",
                          }}
                          exit={{ opacity: 0, height: 0 }}
                      >
                        {
                          array.map((key:any) => (
                              <div key={index}>
                                <div className="text-gray-500 pt-2">{dp.jpdates[key]}</div>
                                <div className="divide-y">
                                  {dp.transactions[key].map((tr: any, index: any) => (
                                      <div
                                          key={index}
                                          className="flex py-4 mt-2 items-center"
                                      >
                                        <img
                                            src={tr.offer.picture_url}
                                            alt={"Item"}
                                            className="w-24 mr-2 rounded-md"
                                        />
                                        <div className="flex-1 min-w-0">
                                          <div className={classNames(
                                              "text pt-1 font-semibold",
                                              tr.category != 'paid' ? "text-gray" : ""
                                          )}
                                          >
                                            {tr.offer.discount_value}P - {status[tr.category]}
                                          </div>
                                          <div className={classNames(
                                              "text-sm truncate",
                                              tr.category != 'paid' ? "text-gray" : ""
                                          )}>
                                            {tr.offer.title}
                                          </div>
                                          <div className={classNames(
                                              "text-sm pt-1 truncate",
                                              tr.category != 'paid' ? "text-gray" : ""
                                          )}>
                                            {tr.offer.subtitle}
                                          </div>
                                        </div>
                                      </div>
                                  ))}
                                </div>
                              </div>
                          ))}
                      </motion.div>
                    </div>
                )}
              </AnimatePresence>
            </div>
        )
      })}
    </motion.div>
  );
};
