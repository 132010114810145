interface Props {
  type: string;
  value?: string;
  placeholder: string;
  onChange?: Function;
  disabled?: boolean;
}

export const TextField = (props: Props) => {
  const { type, placeholder, value, onChange, disabled = false } = props;
  return (
    <div className="mt-2 mb-6 relative pb-2 border-b border-gray">
      <input
        type={type}
        onChange={(e) => {
          if (onChange) {
            onChange(e.target.value);
          }
        }}
        disabled={disabled}
        value={value}
        className="block w-full rounded-md outline-none text-sm bg-white"
        placeholder={placeholder}
      />
    </div>
  );
};
