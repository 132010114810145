import {
  SET_FAVORITE_STORES,
  SET_SELECTED_STORE,
  SET_STORE_LOADING,
} from "./stores.types";
import MembersService from "../../services/members.service";

export const setFavoriteStores = (data) => {
  return {
    type: SET_FAVORITE_STORES,
    payload: data,
  };
};

export const setSelectedStore = (data) => {
  return {
    type: SET_SELECTED_STORE,
    payload: data,
  };
};

export const setStoreLoading = (data) => {
  return {
    type: SET_STORE_LOADING,
    payload: data,
  };
};
export const fetchFavoriteStores = (catId) => {
  return (dispatch, getState) => {

    MembersService.getFavoriteStores()
        .then((responseJson) => {

          let starStore = localStorage.getItem("fav-store") ? JSON.parse(localStorage.getItem("fav-store")) : null;
          if (starStore) {
            let storeExists = responseJson.data.filter(
                (c) => c.id === starStore.id
            )[0];
            if (!storeExists) {
              localStorage.removeItem("fav-store");
            }
          }
          dispatch({
            type: SET_FAVORITE_STORES,
            payload: responseJson.data
          });
        })
        .catch((error) => {
          console.log(error);
        });
  };
};
