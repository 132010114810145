import React, { useEffect } from 'react';
import {useSelector} from '../redux/store'
declare global {
    interface Window {
        dataLayer: Array<Object>;
        first_load:any;
    }
}
export function useOnScreen(targetRef: React.RefObject<HTMLElement>) {
    // const auth:any = useSelector((state) => state.authentication);
    // const user:any = useSelector((state:any) => state.global.user);
    const user = JSON.parse(localStorage.getItem("user-data")!);

    const first_load:any = useSelector((state:any) => state.global.first_load);
    useEffect(()=>{
        window["first_load"] = first_load
        // console.log('changed',first_load)
    },[first_load])
    let user_id = user ? user.id : 0;
    const observer = new IntersectionObserver(
        ([entry]) => {
            if (entry.isIntersecting) {
                if(entry.intersectionRatio>=.1 && entry.intersectionRatio<=.99){
                    // console.log(entry.intersectionRatio)
                    window.dataLayer = window.dataLayer || [];
                    const data = {
                        intersectionRatio: entry.intersectionRatio,
                        event: 'dm_partial_view_event',
                        get_key2: `offer_list_${targetRef.current?.dataset.id}`,
                        user_id: `${user_id}`,
                        dm_screen_type: 'Partial List View',
                        engagement_time_msec : 0,
                        _et:0
                    };
                    console.log(data)
                    window.dataLayer.push(data);
                }else if(entry.intersectionRatio==1){
                    // console.log(entry.intersectionRatio)
                    window.dataLayer = window.dataLayer || [];
                    const data = {
                        intersectionRatio: entry.intersectionRatio,
                        event: 'dm_full_view_event',
                        get_key2: `offer_list_${targetRef.current?.dataset.id}`,
                        user_id: `${user_id}`,
                        dm_screen_type: 'Full List View',
                        engagement_time_msec : 0,
                        _et:0
                    };
                    console.log(data)
                    window.dataLayer.push(data);
                    if(window["first_load"]){
                        const data = {
                            intersectionRatio: entry.intersectionRatio,
                            event: 'dm_partial_view_event',
                            get_key2: `offer_list_${targetRef.current?.dataset.id}`,
                            user_id: `${user_id}`,
                            dm_screen_type: 'Partial List View',
                            engagement_time_msec : 0,
                            _et:0
                        };
                        console.log(data)
                        window.dataLayer.push(data);
                    }
                }
                return;
            }
        },
        {
            root: null,
            threshold: [.1,1.0]
        }
    );

    useEffect(() => {
        if (targetRef.current) observer.observe(targetRef.current);

        return () => {
            observer.disconnect();
        };
    }, []);
}