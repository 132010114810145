import { IonContent, IonPage } from "@ionic/react";
import { useTranslation } from "react-i18next";
import step1 from "./howto/1.jpg";
import step2 from "./howto/2.jpg";
import step3 from "./howto/3.jpg";
import step4 from "./howto/4.jpg";

const steps = [
  // {
  //   image: step1,
  //   heading: "❶ お気に入り店選を選択",
  //   subheading:
  //     "まずは、お好きな店舗を一つ選んでください。店舗エリアや店舗名で探せます",
  // },
  {
    image: step2,
    heading: "❶ クーポンを一覧から追加",
    subheading:
      "お得な商品クーポンをバスケットに入れましょう。",
  },
  {
    image: step3,
    heading: "❷ クーポン商品をお店で購入！",
    subheading:
      "選んだクーポン商品をお店でGET!　お会計時にオーカードの提示をお忘れなく。後日ポイントがもらえます！",
  },
  {
    image: step4,
    heading: "❸ ポイント獲得履歴を確認！",
    subheading:
      "クーポンポイントの獲得履歴、獲得したポイントの合計などをマイポイントページから確認できます。",
  },
];

export const HowToUseApp = () => {
  const { t } = useTranslation();
  return (
    <IonPage className="pt-16">
      <IonContent>
        {/* <BackToInfo /> */}
        <div className="px-6 dark:bg-white dark:text-black">
          <div className="py-3 text-xl font-semibold">
            {t("How to use the App")}
          </div>
          <hr />
          <div className="py-4 text">
            クータッチへようこそ！お得なクーポンをお楽しみください！
          </div>
          {/* <div className="py-1 underline">1. 初めての方</div>
          <div className="py-1 underline">2. 店舗リストから探す</div>
          <div className="py-1 underline">3. 店舗エリア選択から探す</div> */}

          {/* <div className="pt-6 pb-4 text-xl font-semibold">1. 初めての方</div> */}

          {steps.map((step) => (
            <div key={step.heading}>
              <img
                className="block w-full m-auto rounded-lg"
                src={step.image}
              />
              <div className="pt-4 text-xl font-bold">{step.heading}</div>
              <div className="pt-2 text-sm">{step.subheading}</div>
              <hr className="mt-4 mb-8" />
            </div>
          ))}
        </div>
      </IonContent>
    </IonPage>
  );
};
