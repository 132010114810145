import {
  IonAlert,
  IonButton,
  IonCheckbox,
  IonContent,
  IonItem,
  IonLabel,
  IonPage,
  IonTextarea,
  // useIonToast,
} from "@ionic/react";
import { useEffect, useState } from "react";
import Checkbox from "react-custom-checkbox";
import { useTranslation } from "react-i18next";
import { TextField } from "../../../../components/text-field/text-field";
// import { PopupEnquirySent } from "./popup-enquiry-sent";
import MembersService from "../../../../services/members.service";
import { useHistory } from "react-router";
import { setAppLoading } from "../../../../redux/global/global.actions";
import { fetchHolderCards } from "../../../../redux/holder-cards/holder-cards.actions";

import { connect } from "react-redux";
import {CheckIcon} from "@heroicons/react/solid";
const mapStateToProps = (state: any) => {
  return {
    state: state,
    isAppLoading: state.global.isAppLoading,
    cards: state.cards.holders,
    cardsLoading: state.cards.loading,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    fetchHolderCards: () => dispatch(fetchHolderCards()),
    setAppLoading: (data: any) => dispatch(setAppLoading(data)),
  };
};
interface Props {
  state: any;
  isAppLoading: boolean;
  fetchHolderCards: Function;
  cards: any[];
  cardsLoading: boolean;
}

export function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

export const InquiryComponent = (props: Props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [checked, setChecked] = useState(false);
  const [email, setEmail] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [cardNumber, setCardNumber] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  // const [present,] = useIonToast();
  const [submitDone, setSubmitDone] = useState<boolean>(false);
  // const [selectedInquiryCategory, setSelectedInquiryCategory] = useState("");
  const validateEmail = (email: string) => {
    const re =
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  const submitInquiry = () => {
    MembersService.inquiry(email, firstName, lastName, message, cardNumber)
        .then(() => {
          setSubmitDone(true);
          // present("Enquiry submit", 2000);
          // history.push("/home");
        })
        .catch(() => {
          // present("Enquiry failed", 2000);
          // history.push("/home");
          setSubmitDone(true);
        });
  };
  useEffect(() => {
    if (props.cards.length > 0) {
      // console.log(props.cards[0].ref)
      setCardNumber(props.cards[0].ref);
    }
  }, []);
  return (
      <IonPage className="pt-16">
        <IonContent>
          {/* <BackToInfo /> */}
          <div className="px-6 dark:pb-6 dark:bg-white dark:text-black">
            <div className="text-xl font-semibold py-3">{t("Inquiry")}</div>
            <hr className="mb-6" />
            <div className="px-6 mb-6 font-bold ...">
              <div>
                クータッチサービスは 2022年4月25日にリニューアルいたしました。
              </div>
              <br />
              <div>
                リニューアル前のサービスについては「store_at_okuwa@id-s.net」にメールにてお問い合わせください。
              </div>
            </div>
            <hr className="mb-6" />
            <div className="text-sm text-gray-500">
              {t("Fill inquiry")}
            </div>
            <br />
            <div className="text-sm text-gray-500">{t("Inquiry note")}</div>
          </div>

          {/* <div className="m-4">
          <InquiryCategory setSelectedInquiryCategory={setSelectedInquiryCategory} />
        </div> */}

          <div className="px-6 mt-6 dark:bg-white dark:text-black dark:mt-0 dark:pt-6">
            <IonLabel className="account-editing font-semibold">
              メールアドレス
            </IonLabel>
            <TextField
                placeholder="電子メールアドレス"
                value={email}
                onChange={(value: any) => setEmail(value)}
                type="text"
            />
            {email && !validateEmail(email) && (
                <div className="text-red-600 text-xs -mt-3 mb-5">
                  メールアドレスを正しく入力してください。
                </div>
            )}
            <div className="flex">
              <div>
                <IonLabel className="account-editing font-semibold">姓</IonLabel>
                <TextField
                    placeholder="姓"
                    value={lastName}
                    onChange={(e: any) => setLastName(e)}
                    type="text"
                />
              </div>
              <div className="w-8" />
              <div>
                <IonLabel className="account-editing font-semibold">名</IonLabel>
                <TextField
                    placeholder="名"
                    value={firstName}
                    onChange={(e: any) => setFirstName(e)}
                    type="text"
                />
              </div>
            </div>

            <IonLabel className="account-editing">お問合わせ内容</IonLabel>
            <IonTextarea
                className="border-2"
                placeholder="お問合わせ内容を入力してください"
                value={message}
                onIonChange={(e: any) => setMessage(e.detail.value!)}
            />
          </div>

          <div className="px-6 dark:mt-0 dark:pt-6 text text-875 font-semibold dark:bg-white dark:text-black">個人情報の取り扱いについて</div>
          <div className="block mt-1 dark:mt-0 dark:pt-1 dark:bg-white dark:text-black">
            <div className="overflow-y-auto h-60 text-xs" style={{ color: "#525151" }}>

              <div className="px-6 mt-6">
                <div className="text py-1">
                  1. 事業者の氏名又は名称
                </div>
                <div className="px-3 py-1 py-1 mt-1 mb-1">
                  カタリナ マーケティング ジャパン株式会社
                </div>

                <div className="text py-1">
                  2. 個人情報保護管理者（若しくはその代理人）の職名、所属及び連絡先
                </div>
                <div className="px-3 py-1 py-1 mt-1 mb-1">
                  管理者職名：　法務部部長
                </div>
                <div className="px-3 py-1 py-1 mt-1 mb-1">
                  所属部署：　カタリナ マーケティング ジャパン 株式会社　法務部
                </div>
                <div className="px-3 py-1 py-1 mt-1 mb-1">
                  メールアドレス：privacypolicy_otoiawase@catalinamarketing.co.jp
                </div>
                <div className="text py-1">
                  3. 個人情報の利用目的
                </div>
                <div className="px-3 py-1 mt-1 mb-1">
                  お問い合わせ対応（ご本人様への連絡を含む）のため
                </div>
                <div className="text py-1">
                  4. 個人情報取扱いの委託および第三者への提供
                </div>
                <div className="px-3 py-1 mt-1 mb-1">
                  当社は事業運営上、前項利用目的の範囲に限って、上記入力欄にて入力いただいた個人情報を外部に委託することがあります。この場合、個人情報保護水準の高い委託先を選定し、個人情報の適正管理・機密保持についての契約を交わし、適切な管理を実施させます。<br />
                  {/*また、当社は、お問い合わせいただいた内容がオークワアプリの運営会社である株式会社オークワより回答すべきものであると判断した場合、上記入力欄にて入力いただいた個人情報を株式会社オークワおよび株式会社オークワが指定する第三者に提供することがあります。*/}
                </div>
                <div className="text py-1">
                  5. 個人情報の開示等の請求
                </div>
                <div className="px-3 py-1 mt-1 mb-1">
                  当社は、保有する個人情報に関して、ご本人又は代理人から次の各号のご請求があった場合には、ご本人又は代理人からのご請求であることを確認のうえ、日本国の個人情報の保護に関する法律の定めに従って対応いたします。
                </div>
                <div className="px-3 py-1 mt-1 mb-1">
                  これらのご請求については、「個人情報問い合わせ窓口（privacypolicy_otoiawase@catalinamarketing.co.jp）」までご連絡ください。
                </div>
                <div className="px-3 py-1 mt-1 mb-1">
                  (1) 利用目的の通知
                </div><div className="px-3 py-1 mt-1 mb-1">
                (2) 弊社が保有する個人情報の開示
              </div><div className="px-3 py-1 mt-1 mb-1">
                (3) 弊社が保有する個人情報に係る訂正、追加又は削除
              </div><div className="px-3 py-1 mt-1 mb-1">
                (4) 利用提供、消去又は第三者への提供の停止
              </div>
                <div className="px-3 py-1 mt-3 mb-1">
                  【お問合せ窓口】
                </div>
                <div className="px-3 py-1 mt-1 mb-1">
                  個人情報保護方針に関するお問合せにつきましては、下記窓口で受付けております。
                </div>
                <div className="px-3 py-1 mt-1 mb-1">
                  カタリナ マーケティング ジャパン株式会社　個人情報問合せ窓口
                </div>
                <div className="px-3 py-1 mt-1 mb-1">
                  メールアドレス：privacypolicy_otoiawase@catalinamarketing.co.jp
                </div>
                <div className="px-3 py-1 mt-1 mb-1">
                  ※ 土・日曜日、祝日、年末年始、ゴールデンウィーク期間は翌営業日以降の対応とさせていただきます。
                </div>
                <div className="text">
                  6. 個人情報を提供されることの任意性について
                </div>
                <div className="px-3 py-1 mt-1 mb-1">
                  ご本人様が当社に個人情報を提供されるかどうかは任意によるものですが、必要な項目を頂けない場合、適切な対応ができない場合がございます。
                </div>
                {/*<div className="px-3 py-1 mt-1 mb-1">*/}
                {/*  上記事項に同意します（必須）*/}
                {/*</div>*/}
              </div>
            </div>
          </div>
          <div className="pt-6 dark:bg-white dark:text-black">
            <fieldset className="py-3">

              {/*<div className="relative flex items-start pl-6">*/}
              {/*  <div className="flex items-center h-5">*/}
              {/*    <input*/}
              {/*        checked={checked}*/}
              {/*        onChange={(e) => setChecked(e.target.checked)}*/}
              {/*        id="comments"*/}
              {/*        aria-describedby="comments-description"*/}
              {/*        name="comments"*/}
              {/*        type="checkbox"*/}
              {/*        className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded-full"*/}
              {/*    />*/}
              {/*  </div>*/}
              {/*  <div className="ml-3 text-sm">*/}
              {/*    <span id="comments-description" className="text-gray-500">上記事項に同意します(必須)</span>*/}
              {/*  </div>*/}
              {/*</div>*/}
              <div className="px-4">
                <Checkbox
                    checked={checked}
                    onChange={(value: any) => setChecked(value)}
                    icon={<CheckIcon className="h-5 w-5" />}
                    id="comments"
                    aria-describedby="comments-description"
                    borderColor="rgba(0,0,0,0.3)"
                    borderRadius={5}
                    size={18}
                    label="上記事項に同意します(必須)"
                />
              </div>

            </fieldset>
            {/*<IonItem lines="none" className="flex items-center mt-6">*/}
            {/*  <IonCheckbox*/}
            {/*      checked={checked}*/}
            {/*      className="checkbox-square h-5 w-5"*/}
            {/*      onIonChange={(e) => setChecked(e.detail.checked)}*/}
            {/*  />*/}
            {/*  <IonLabel style={{ fontSize: "0.875rem" }} className="text-875">上記事項に同意します(必須)</IonLabel>*/}
            {/*</IonItem>*/}
          </div>
          <div className="dark:bg-white dark:text-black">
            <IonButton

                disabled={!email || !validateEmail(email) || !firstName || !lastName || !message || !checked}
                onClick={() => submitInquiry()}
                color="white"
                className={classNames(
                    "rounded-xl border border w-full",
                    !email || !validateEmail(email) || !firstName || !lastName || !message || !checked
                        ? "border-grey-100 text-grey-100"
                        : "border-slate-700  text-gray-800",

                )}
            >
              <p className={classNames(
                  "font-bold",
                  !email || !validateEmail(email) || !firstName || !lastName || !message || !checked
                      ? "border-grey-200 text-slate-400"
                      : "text-gray-800",

              )}>お問い合わせを送信する</p>
            </IonButton>
          </div>

          {submitDone && <IonAlert
              mode="ios"
              isOpen={true}
              onDidDismiss={() => { }}
              cssClass="confirm-modal"
              header={t("ENQUIRY_SUCCESS_TITLE")}
              message={
                '<p class="text-gray-500">' + t("ENQUIRY_SUCCESS_MESSAGE") + '</p>'
              }
              buttons={[
                {
                  text: "はい",
                  cssClass: "py-6",
                  handler: () => {
                    // console.log("Confirm Okay");
                    history.replace('/home');
                  },
                },
              ]}
          />}
          {/*<div className="flex h-full dark:bg-white"></div>*/}
        </IonContent>
      </IonPage>
  );
};
export const Inquiry = connect(mapStateToProps, mapDispatchToProps)(InquiryComponent);

