import { SET_CATEGORIES, SET_SELECTED_CATEGORY } from "./categories.types";
import MembersService from "../../services/members.service";

export const setCategories = (data) => {
  return {
    type: SET_CATEGORIES,
    payload: data,
  };
};

export const setSelectedCategory = (data) => {
  return {
    type: SET_SELECTED_CATEGORY,
    payload: data,
  };
};

export const fetchCategories = (catId) => {
  return (dispatch, getState) => {
    const {stores, categories, cards} = getState()
    MembersService.getCategories()
        .then((responseJson) => {
          if (responseJson.data.length) {
            let allCategories = [];
            let categories = allCategories.concat(responseJson.data)
            categories.sort(function (a, b) {
              if (a.precedence > b.precedence) {
                return 1;
              } else if (a.precedence < b.precedence) {
                return -1;
              } else {
                return 0;
              }
              // return (a.precedence<b.precedence)
            });
            categories.unshift({
              id: 1,
              name: "全て",
              tag: "All",
              precedence: 0,
            });
            dispatch({
              type: SET_CATEGORIES,
              payload: categories
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
  };
};
