
import { IonPage, IonContent } from "@ionic/react";

declare global {
  interface Window {
    dataLayer: Array<Object>;
  }
}

export const ErrorPage = () => {

  return (
    <IonPage className="dark:bg-white">
      <IonContent>
        <div className="px-6 pt-6 mt-6">

          {/* <div className="account-editing text-sm font-bold">	&lt; 拾客樣 の 知 世  &gt;</div> */}
          <br />
          <div className="account-editing text-sm font-bold">
            現在、システムメンテナンスのためクータッチ画面が閲覧できません。時間をおいて再度アクセスください。
          </div><br />
          <div className="account-editing text-sm font-bold">
            バスケットに入れていたクーポン対象商品を今ご購入いただく場合は問題なく後日ポイントが付与されます。
          </div><br />
          <div className="account-editing text-sm font-bold">
            ご不便をおかけし誠に申し訳ございません。何卒ご理解とご了承の程、よろしくお願い申し上げます。
          </div>

          {/* <div className="font-bold text-sm" style={{ marginTop: "3.375rem" }}>利用規約</div> */}

        </div>

        <div>

        </div>
      </IonContent>
    </IonPage>

  );
};
