export const Loader = () => {
    return (
        <div className="bg-white flex flex-col mt-2 border border-gray-300 shadow rounded-md p-4 max-w-sm w-full mx-auto">
            <div className="animate-pulse flex flex-1 flex-col items-center">
                <div className="flex flex-col items-center flex-1 w-full">
                    <div className="rounded-full bg-slate-200 h-20 w-20 mt-4"></div>
                    <div className="h-2 bg-slate-200 rounded w-28 mt-8"></div>
                    <div className="h-2 bg-slate-200 rounded w-28 mt-4"></div>
                </div>
                <div className="flex items-center justify-between w-full mt-2">
                    <div>
                        <div className="h-2 bg-slate-200 rounded w-10 "></div>
                        <div className="h-2 bg-slate-200 rounded w-12 mt-2"></div>
                    </div>
                    <div>
                        <div className="rounded-full bg-slate-200 h-10 w-10"></div></div>
                </div>
            </div>
        </div>
    );
};
