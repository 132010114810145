import { ChevronRightIcon, XIcon } from "@heroicons/react/outline";
import {LocationMarkerIcon, StarIcon} from "@heroicons/react/solid";
import { IonSearchbar } from "@ionic/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, useStore } from "react-redux";
import { Link } from "react-router-dom";
import { StoreAddOrDeleteModal } from "../../components/store-add-or-delete-modal/store-add-or-delete-modal";
import { StoreTabs } from "../../components/store-tabs/store-tabs";
import {setAppLoading, setEvent} from "../../redux/global/global.actions";
import {
  addToFavStores,
  fetchCities,
  fetchPrefectures,
  fetchStores,
  removeFromFavStores,
  setCity,
  setPrefecture,
  setStore,
} from "../../redux/retailers/retailers.actions";
import {
  fetchFavoriteStores, setSelectedStore,
  setStoreLoading,
} from "../../redux/stores/stores.actions";
import { StoreSelectionFlow } from "../store-selection-flow/store-selection-flow";

import pin from "./pin.png";
import sadface from "./sadface.png";

const mapStateToProps = (state: any) => {
  return {
    favoriteStores: state.stores.favoriteStores,
    selectedStore: state.stores.selectedStore,
    loading: state.stores.loading,
    favoriteStoresLoaded: state.stores.favoriteStoresLoaded,
    storesLoaded: state.stores.storesLoaded,
    isAppLoading: state.global.isAppLoading,
    prefectures: state.retailers.prefectureList,
    prefecture: state.retailers.prefecture,
    cities: state.retailers.cities,
    city: state.retailers.city,
    stores: state.retailers.stores,
    all_stores: state.retailers.all_stores,
    store: state.retailers.store,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    fetchFavoriteStores: () => dispatch(fetchFavoriteStores()),
    setStoreLoading: (data: any) => dispatch(setStoreLoading(data)),
    setAppLoading: (data: any) => dispatch(setAppLoading(data)),
    fetchPrefectures: (retailer: any) => dispatch(fetchPrefectures(retailer)),
    setPrefecture: (prefecture: any) => dispatch(setPrefecture(prefecture)),
    removeFromFavStores: (storeId: any) =>
      dispatch(removeFromFavStores(storeId)),
    setCity: (city: any) => dispatch(setCity(city)),
    setStore: (store: any) => dispatch(setStore(store)),
    addToFavStores: (store: any) => dispatch(addToFavStores(store)),
    fetchCities: (retailer: any, prefecture: any) =>
      dispatch(fetchCities(retailer, prefecture)),
    fetchStores: (retailer: any, prefecture: any, city: any) =>
      dispatch(fetchStores(retailer, prefecture, city)),
    setSelectedStore: (data: any) => dispatch(setSelectedStore(data)),
    setEvent: (data: any) => dispatch(setEvent(data)),
  };
};

interface Props {
  selectedStore: any;
  setSelectedStore: Function;
  setEvent: Function;
  loading:boolean;
  favoriteStores: any[];
  storesLoaded: boolean;
  favoriteStoresLoaded: boolean;
  isAppLoading: boolean;
  fetchFavoriteStores: Function;
  setStoreLoading: Function;
  setAppLoading: Function;
  prefectures: any[];
  prefecture: any;
  cities: any[];
  city: any;
  stores: any[];
  all_stores: any[];
  store: any;
  fetchPrefectures: Function;
  setPrefecture: Function;
  setCity: Function;
  setStore: Function;
  fetchCities: Function;
  fetchStores: Function;
  addToFavStores: Function;
  removeFromFavStores: Function;
  setSelectedCategory: Function;
  filters: any[];
}

const StoreTabFavoritesComponent = (props: Props) => {
  const { t } = useTranslation();
  const [selectedStore, setSelectedStore] = useState<any>(null);
  let starStore:any = JSON.parse(localStorage.getItem('fav-store')|| "{}");
  if (starStore && starStore.id) {
    // console.log(starStore)
  }
  return (
    <div>
      {props.loading && <div className="rounded-md p-4 max-w-sm w-full mx-auto">
        <div className="animate-pulse flex space-x-4">
          <div className="rounded-full bg-slate-200 h-10 w-10"></div>
          <div className="flex-1 space-y-6 py-1">
            <div className="h-2 bg-slate-200 rounded"></div>
            <div className="space-y-3">
              <div className="grid grid-cols-3 gap-4">
                <div className="h-2 bg-slate-200 rounded col-span-2"></div>
                <div className="h-2 bg-slate-200 rounded col-span-1"></div>
              </div>
              <div className="h-2 bg-slate-200 rounded"></div>
            </div>
          </div>
        </div>
      </div>}
      {!props.loading &&props.favoriteStores.length === 0 && (
        <div className="flex flex-col p-10 items-center justify-center flex-1 dark:text-black">
          <img src={sadface} className="w-20 mt-8 opacity-60" />
          <div className="text-center pt-7 font-bold text-gray-600">
            <div className="py-2">{t("NO_FAV_STORE_1")}</div>
            <div className="py-2">
              <Link
                className="underline text-okuwa_blue"
                onClick={() => props.setSelectedCategory(props.filters[1])}
                to={"/stores"}
              >
                {t("NO_FAV_STORE_2")}
              </Link>
              {t("NO_FAV_STORE_3")}
            </div>
            <div className="py-2">
              <Link
                className="underline text-okuwa_blue"
                onClick={() => props.setSelectedCategory(props.filters[2])}
                to={"/stores"}
              >
                {t("NO_FAV_STORE_4")}
              </Link>
              {t("NO_FAV_STORE_5")}
            </div>
          </div>
        </div>
      )}

      {!props.loading && <div className="px-4 grid grid-cols-1 gap-2 pt-3 bg-white divide-y dark:text-black">
        {props.favoriteStores.map((item) => (
            <li className="flex py-4 pr-2 items-center" key={item.id} onClick={()=>{
              props.setSelectedStore(item);
              props.setEvent("store_changed");
            }}>
              {/* <ConfirmModal message={'削除しますか？'} open={deleteModalOpen} setOpen={setDeleteModalOpen} onConfirm={()=>{}}/> */}

              <div className="flex-shrink-0 pl-2">
                {/*<img*/}
                {/*    src={item.offer.picture_url}*/}
                {/*    alt={"Item"}*/}
                {/*    className="w-24 rounded-md"*/}
                {/*/>*/}
                <div>
                  {starStore && starStore.id == item.id && <StarIcon className="w-5 h-5" color="orange" />}
                  {starStore.id != item.id && <img src={pin} className="w-5" />}

                </div>
              </div>
              <div className="flex-1 flex flex-col justify-center pl-4">
                <div className="text-base font-bold">{item.name}</div>
                {/*<div className="text pt-1 font-semibold"> {cart.offer.discount_value}P</div>*/}
                {/* <div className="text-sm pt-1">有効期限： {item.distance}</div> */}
              </div>
              <div className="flex-shrink-0 flow-root">
                <button
                    type="button"
                    className="flex items-center justify-center text-gray-500"
                >
                  <XIcon
                      className="h-4 w-4"
                      aria-hidden="true"
                      onClick={() => setSelectedStore(item)}
                  />
                </button>
              </div>
            </li>
        ))}
      </div>}
      {selectedStore && (
        <StoreAddOrDeleteModal
          mainButton="RIGHT"
          mode="DELETE"
          title="お気に入り店舗から削除しますか？"
          rightText="No"
          leftText="Yes"
          close={() => setSelectedStore(null)}
          isOpen={!!selectedStore}
          onRightClick={() => setSelectedStore(null)}
          onCancel={() => setSelectedStore(null)}
          onLeftClick={() => {
            props.setStoreLoading(true);
            props.removeFromFavStores(selectedStore.id);
            setSelectedStore(null);
            setTimeout(() => props.fetchFavoriteStores(), 1000);
          }}
          store={selectedStore}
        />
      )}
    </div>
  );
};

export const StoreTabFavorites = connect(
  mapStateToProps,
  mapDispatchToProps
)(StoreTabFavoritesComponent);
