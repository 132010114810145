import { IonContent, IonPage } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { classNames } from "../../header/header";

const faqs = [
  {
    key: 1,
    question: "Q. クータッチとは何ですか？",
    answer: `「クータッチ」は、いつものお買いものでポイントがもらえる、お得なスマートクーポンサービスです。運営会社は、カタリナマーケティングジャパン株式会社です。
       <a class="underline" href="https://www.catalinamarketing.co.jp/about/">https://www.catalinamarketing.co.jp/about/ </a>`,
  },
  {
    key: 2,
    question: "Q. 本サービスの利用は無料ですか？",
    answer: "はい、無料です。",
  },
  {
    key: 3,
    question: "Q. クーポンはどうやって利用できますか？",
    answer: `1. まずはクータッチを開いてください<br/>
      2. 利用したいクーポンを選択してバスケットに入れてください<br/>
      3. クーポン対象商品をお店で購入ください<br/>
      　※オーカードの提示が必要です<br/>
      4. 後日ポイントが還元されます。`,
  },
  {
    key: 4,
    question: "Q. クーポンはどこのお店で使えますか？",
    answer: `全ての店舗にてご利用いただけます。※企画によって店舗限定企画もございます。`,
  },
  {
    key: 5,
    question: "Q. クーポンの対象商品がお店にない場合はどうしたら良いですか？",
    answer: `クーポンは、対象商品が実際に店舗にて取り扱いがある場合のみ、利用いただけます。お手数ですが、他店舗にてご利用ください。
      選んだクーポンの商品がお買い物の際の店舗でお取り扱いがない場合があることをご了承願います。`,
  },
  {
    key: 6,
    question: "Q. 他クーポンとの併用制限はありますか？",
    answer: `1つのクーポンにつきポイント付与は原則一回となります。いずれのポイント分になるかはお選びいただけませんのでご了承ください。 
      他キャンペーン（同時期に実施の同一商品を対象）と併用した場合、ポイント付与対象外となる場合があります。`,
  },
  {
    key: 7,
    question: "Q. 1つのクーポンを複数回利用することは可能ですか？",
    answer: `１回のクーポン選択で１度のポイント付与となります。クーポンは一度ご利用後、再度ご利用可能となります。※利用回数に制限がある場合もございます。`,
  },
  // {
  //   key: 8,
  //   question: "Q. 店舗の追加はどのようにすれば良いですか？",
  //   answer: `現在選択中のお気に入り店舗名の右の「編集」をタップしてください。
  //     「店舗エリアから選択」もしくは「店舗名で検索」で追加したい店舗を選択いただけます。`,
  // },
  {
    key: 9,
    question: "Q. クーポンを使ってからポイントが付くまでにどれくらいかかりますか？",
    answer: `ポイント付与までの期間は、クーポンご利用後約2日間となります。（※企画により前後する場合がございます。）対象商品の購入が確認されますとクーポンはバスケット画面からその右のマイポイント画面に移ります。ポイント付与が確認されますと「付与完了」の表示となります。<br/>
    　※利用条件が満たされていない場合、ポイントは付与されませんのでご了承ください。`,
  },

  {
    key: 10,
    question: "Q. 登録情報の変更はどうすれば良いですか？",
    answer: "オークワアプリで案内されている手順に従い、登録情報を変更ください。",
  },
  {
    key: 11,
    question: "Q. 機種変更した後に本サービスへどうやって訪問できますか？",
    answer: "変更後の機種にインストールしたオークワアプリから再度ご訪問いただけます。",
  },
  {
    key: 12,
    question: "Q. 退会するにはどうすれば良いですか？",
    answer: "オークワアプリで案内されている手順に従い、退会手続きをお取りください。",
  },
  {
    key: 13,
    question: "Q. クータッチについて問い合わせ先はどこですか？",
    answer: `次のお問い合わせフォームをご利用ください：<br/> `,
  },
  {
    key: 14,
    question: "",
    answer: `<br/><a href='/info/inquiry'>
    お問い合わせ</a> `,
  },
  {
    key: 15,
    question: "Q. 問い合わせの返信をもらうために必要な設定を教えてください。",
    answer: `以下のメールアドレスが受信拒否設定されていないことをご確認ください。<br/>
      contact@catalinacoupons.jp <br/>
      noreply@catalinacoupons.jp`,
  },
];

export const Faq = () => {
  const { t } = useTranslation();
  return (
    <IonPage className="pt-16">
      <IonContent>
        {/* <div className="underline p-4">{t("Return to My Account screen")}</div> */}
        <div className="px-6 dark:bg-white dark:text-black">
          <div className="text-xl font-semibold py-3">{t("FAQ")}</div>
          <hr className="mb-6" />
          {faqs.map((faq) => (
            <div className="mb-5" key={faq.key}>
              <div
                style={{ color: "#383636" }}
                className="text-sm font-semibold"
              >
                {faq.question}
              </div>
              <div
              className={classNames(
                faq.answer.includes('お問い合わせ</a>')
                  ? "font-bold text-blue-600 -mt-8 underline underline-offset-1 ..."
                  : "py-1 ",
                "text-xs text-gray-800"
              )}
                dangerouslySetInnerHTML={{
                  __html: faq.answer,
                }}
              />
            </div>
          ))}
        </div>
      </IonContent>
    </IonPage>
  );
};
