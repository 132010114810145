import { XIcon } from "@heroicons/react/outline";
import { format, formatDistance, formatRelative, isBefore, setHours, setMinutes, setSeconds, subDays } from 'date-fns'
import { ja } from 'date-fns/locale'
import MembersService from "../../services/members.service";
import { ConfirmModal } from "../confirm-modal/confirm-modal";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import GTMService from "../../services/gtm.service";
import { fetchBasket, fetchOffers, setBasketLoading } from "../../redux/offer-list/offer-list.actions";
import { setSelectedOffer } from "../../redux/offer-details/offer-details.actions";
import { connect } from "react-redux";
import { setEvent } from "../../redux/global/global.actions";

const mapStateToProps = (state: any) => {
  return {
    state: state,
    offers: state.offerList.offers,
    basket: state.offerList.basket,
    selectedStore: state.stores.selectedStore,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setSelectedOffer: (data: any) => dispatch(setSelectedOffer(data)),
    fetchBasket: () => dispatch(fetchBasket()),
    fetchOffers: () => dispatch(fetchOffers()),
    setEvent: (event: any) => dispatch(setEvent(event)),
    setBasketLoading: (loading: boolean) => dispatch(setBasketLoading(loading)),
  };
};
interface Props {
  state: any;
  offers: any[];
  basket: any[];
  fetchBasket: Function;
  setSelectedOffer: Function;
  selectedStore: any;
  fetchOffers: Function;
  setBasketLoading: Function;
  setEvent: Function;
}

export const CartCardComponent = (props: any) => {
  const history = useHistory();
  const { cart, fetchOffers, fetchBasket } = props;
  // console.log(cart)
  // let adjustForTimezone = (date:Date) => {
  //   var timeOffsetInMS:number = date.getTimezoneOffset() * 60000;
  //   date.setTime(date.getTime() + timeOffsetInMS);
  //   return date
  // }

  let setDistance = (expired_at: any) => {
    let expiry_time = new Date(expired_at);
    expiry_time = setHours(expiry_time, 23);
    expiry_time = setMinutes(expiry_time, 59)
    expiry_time = setSeconds(expiry_time, 59);
    console.log(expiry_time)
    
    console.log(isBefore(expiry_time, new Date()))
    if(isBefore(expiry_time, new Date())){
      return "有効期限終了"
    } else {
      return "有効期限：あと" + formatDistance(expiry_time, new Date(), { locale: ja })
    }
  }
  let distance = setDistance(cart.expired_at);
  // console.log(distance)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const setSelectedOffer = () => {
    return MembersService.getCouponDetail(cart.offer.id);
  };
  const removeCouponFromCart = () => {
    MembersService.removeCouponFromBasket(cart.id)
      .then((response) => {
        fetchBasket();
        fetchOffers();
        props.setBasketLoading(false)
        props.setEvent("removedFromCart");
        setDeleteModalOpen(false);
      })
      .catch((error) => {

      });
  }

  const openDetailsPage = async () => {
    const offer = await setSelectedOffer();
    props.setSelectedOffer({
      ...offer.data,
      isAddedInBasket: props.added,
      distance,
    });
    history.push("/product-details/"+offer.data.id);
  }

  return (
    <li className="flex py-4 pr-8 shadow-custom rounded-md mt-2 relative">
      <ConfirmModal message={'削除しますか？'} open={deleteModalOpen} setOpen={setDeleteModalOpen} onConfirm={removeCouponFromCart} />
      <div className="flex-shrink-0 px-3" onClick={openDetailsPage}>
        <img
          src={cart.offer.picture_url}
          alt={"Item"}
          className="w-24 rounded-md"
        />
      </div>
      <div className="flex-1 flex flex-col justify-center overflow-auto">
        <div className="text-base truncate" onClick={openDetailsPage}>{cart.offer.title}</div>
        <div className="text pt-1 font-semibold" onClick={openDetailsPage}> {cart.offer.discount_value}P</div>
        <div className="text-sm pt-1" onClick={openDetailsPage}>{distance}</div>
      </div>
      <div className="flex-shrink-0 flow-root absolute top-0 right-0 p-3">
        <button
          type="button"
          className="flex items-center justify-center text-gray-500"
        >
          <XIcon className="h-5 w-5" aria-hidden="true" onClick={() => setDeleteModalOpen(true)} />
        </button>
      </div>
    </li>
  );
};
export const CartCard = connect(mapStateToProps, mapDispatchToProps)(CartCardComponent);
