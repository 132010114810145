import { SAMPLE_WALLET } from "./SAMPLE_WALLET";
import { SET_WALLET, SET_START_DATE, SET_END_DATE } from "./wallet.types";

const getLast6Months = () => {
  let d = new Date();
  const dates = [];
  for (let i = 0; i <= 6; i++) {
    let dateString = d.toISOString().split("-");
    let year = dateString[0];
    let month = dateString[1];
    dates.push(`${year}/${month}`);
    d.setMonth(d.getMonth() - 1);
  }
  return dates;
};

const getLast36Months = () => {
  let d = new Date();
  const dates = [];
  for (let i = 0; i < 36; i++) {
    let dateString = d.toISOString().split("-");
    let year = dateString[0];
    let month = dateString[1];
    dates.push(`${year}/${month}`);
    d.setMonth(d.getMonth() - 1);
  }
  return dates;
};

const startDates = getLast6Months();
// console.log('Start dates: ', startDates);
const dates = getLast36Months();
// console.log('End dates: ', dates);

const INITIAL_STATE = {
  pending: [],
  refused: [],
  to_be_paid: [],
  validated: [],
  paid: [],
  startDate: startDates[startDates.length - 1],
  endDate: dates[0],
};
// const INITIAL_STATE = SAMPLE_WALLET;

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_WALLET:
      return {
        ...state,
        ...action.payload, // Comment this to use sample data
      };

    case SET_START_DATE:
      // console.log("Setting start date: ", action.payload);
      return {
        ...state,
        startDate: action.payload,
      };

    case SET_END_DATE:
      // console.log("Setting end date: ", action.payload);
      return {
        ...state,
        endDate: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
