import axios from "axios";
import {errorFn, Fn200, preRequestFn, requestErrorFn} from "./errorfunctions";
import UtilityService from "./utility";
const OCP_API_SUBSCRIPTION_KEY= process.env.REACT_APP_OCP_API_SUBSCRIPTION_KEY as string;
const MEMBERS_URL= process.env.REACT_APP_MEMBERS_URL as string;
const LOGIN_URL= process.env.REACT_APP_LOGIN_URL as string;
const SOCIAL_LOGIN_URL= process.env.REACT_APP_SOCIAL_LOGIN_URL as string;
axios.interceptors.request.use(preRequestFn, requestErrorFn);

// Add a response interceptor
axios.interceptors.response.use(Fn200, errorFn);
let headers = {
    "Ocp-Apim-Subscription-Key": OCP_API_SUBSCRIPTION_KEY,
    "Ocp-Apim-Trace": "true",
    "Content-Type": "application/json",
}

const AuthService = {
  login: (email: string, password: string) => {
    return axios.post(
      LOGIN_URL,
      JSON.stringify({
        email,
        password,
      }),
      {
          timeout: UtilityService.timeOut,
          headers
      }
    );
  },
  socialLogin: (token: string, lineUserID: string) => {
    return axios.post(
      SOCIAL_LOGIN_URL,
      JSON.stringify({
        token: token,
        type: 1,
        id: lineUserID
      }),
      {
          timeout: UtilityService.timeOut,
        headers
      }
    );
  },
  signup: (email: string, password: string, holders:[{ ref: string; retailer_id: number; }]) => {
    return axios.post(
      MEMBERS_URL,
      JSON.stringify({
        email,
        password,
        holders
      }),
      {
          timeout: UtilityService.timeOut,
        headers
      }
    );
  },
  signupSocial: (email: string, password: string, social: any) => {
    return axios.post(
      MEMBERS_URL,
      JSON.stringify({
        email,
        password,
        social
      }),
      {
          timeout: UtilityService.timeOut,
        headers
      }
    );
  },
};

export default AuthService;
