import {IonContent, IonPage} from "@ionic/react";
import {useEffect, useState} from "react";
import {DateFilter} from "./date-filter/date-filter";
import {HolderCard} from "./holder-card/holder-card";
import {PointsList} from "./points-list/points-list";
import {TotalSavings} from "./total-savings/total-savings";
import {connect} from "react-redux";
import {getWallet, setWalletEndDate, setWalletStartDate} from "../../redux/wallet/wallet.actions";


// const getLast6Months = () => {
//   let d = new Date();
//   const dates = [];
//   for (let i = 0; i <= 6; i++) {
//     let dateString = d.toISOString().split("-");
//     let year = dateString[0];
//     let month = dateString[1];
//     dates.push(`${year}/${month}`);
//     d.setMonth(d.getMonth() - 1);
//   }
//   return dates;
// };

// const getLast36Months = () => {
//   let d = new Date();
//   const dates = [];
//   for (let i = 0; i < 36; i++) {
//     let dateString = d.toISOString().split("-");
//     let year = dateString[0];
//     let month = dateString[1];
//     dates.push(`${year}/${month}`);
//     d.setMonth(d.getMonth() - 1);
//   }
//   return dates;
// };

const mapStateToProps = (state: any) => {
  return {
    startDate: state.wallet.startDate,
    endDate: state.wallet.endDate,
    wallet: state.wallet,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getWallet: (startDate: any, endDate: any) => dispatch(getWallet(startDate, endDate)),
    setWalletStartDate: (data: any) => dispatch(setWalletStartDate(data)),
    setWalletEndDate: (data: any) => dispatch(setWalletEndDate(data)),
  };
};
interface Props {
  getWallet: Function;
  startDate: string;
  endDate: string;
  wallet:any;
  setWalletStartDate: Function;
  setWalletEndDate: Function;
}

// const CustomYAxisTick = ({ x, y, payload, itemType }: any) => {
//   if (payload.value == 0) {
//     return <Text></Text>;
//   }
//   return (
//     <Text
//       breakAll={true}
//       capHeight={10}
//       fontSize={12}
//       width={70}
//       x={x}
//       y={y}
//       textAnchor="middle"
//       verticalAnchor="start"
//     >
//       {payload.value}
//     </Text>
//   );
// };

export const PointsComponent = (props:Props) => {
  // const [startDates] = useState<string[]>(getLast6Months());
  // const [dates, setDates] = useState<string[]>(getLast36Months());
  const [sumOfHistoryPoints, setSumOfHistoryPoints] = useState(0);
  // const [categoriesDistribution, setcategoriesDistribution] = useState([]);
  const [monthlyDistribution, setMonthlyDistribution] = useState([]);

  useEffect(() => {
    refetchWallet(props.startDate, props.endDate);
  }, []);

  let summateAndCategorize = (result:any, categories:any, monthYearData:any, r:any, o:any) => {
    // console.log(result)
    result.offer && result.offer.categories && result.offer.categories.length>0 && result.offer.categories.forEach((category:any)=>{
      // console.log(category)
      // const monthNames = ["January", "February", "March", "April", "May", "June",
      //   "July", "August", "September", "October", "November", "December"
      // ];

      let date = new Date(result.updated_at)
          // monthName = monthNames[date.getMonth()],
          // month = date.getMonth() + 1,
          // key1 = date.getFullYear() + '-' + (month < 10 ? '0' : '') + month;
      // console.log(date,month,key1, monthName)
      let key = date.toLocaleDateString('ja-JP', {year:"numeric", month:"long"})
      let keyMonthDateJp = date.toLocaleDateString('ja-JP', {month:"long", day:"numeric"})
      let keyMonthDate = date.toISOString().split('T')[0];
      // console.log(date.toLocaleDateString('ja-JP', {year:"numeric", month:"long"}));
      if (!o[key]) {
        o[key] = {key, updated_at: date, total: result.amount, transactions:{}, jpdates:{}, ids: [result.id], dates: new Set([])};
        o[key].dates.add(keyMonthDate)
        o[key].transactions[keyMonthDate] = [result]
        o[key].jpdates[keyMonthDate] = keyMonthDateJp
        r.push(o[key]);
      } else {
        o[key].total = o[key].total+result.amount
        o[key].dates.add(keyMonthDate)
        o[key].jpdates[keyMonthDate] = keyMonthDateJp

        if(o[key].transactions[keyMonthDate]){
          o[key].transactions[keyMonthDate].push(result)
        } else {
          o[key].transactions[keyMonthDate] = [result]
        }
        // o[key].transactions.push(result)
        o[key].ids.push(result.id)
        // r.push(o[key]);
      }
      if(!categories[category.tag]) {
        categories[category.tag] = {}
      }
      if(!categories[category.tag]['points']){
        categories[category.tag]['points'] = 0
      }
      categories[category.tag]['points'] = categories[category.tag]['points'] + result.amount
    })
    if(!result.offer){
      categories['UNCATEGORIZED']['points'] = categories['UNCATEGORIZED']['points'] + result.amount
    }

  }
  useEffect(() => {
    // setTimeout(() => {
    //   if (!props.startDate) {
    //     props.setWalletStartDate(startDates[startDates.length - 1]);
    //   } else {
    //     if (!props.endDate) {
    //       props.setWalletEndDate(dates[0]);
    //     }
    //   }
    // }, 500);

    if(props.wallet){
      let categories:any = {};
      let monthYearData:any = {};
      let r: any = [], o:any = {};
      categories['UNCATEGORIZED'] = {
        points: 0
      }
      // console.log('received the wallet data')
      let sumOfHistoryPoints = 0;
      let dataIntegrityCheck:any = {}
      dataIntegrityCheck['paid'] = {
        transactions:[]
      };
      props.wallet.paid.forEach((result: any) => {
        dataIntegrityCheck['paid'].transactions.push(result.amount)
        sumOfHistoryPoints += result.amount
        result.category = 'paid'
        summateAndCategorize(result, categories, monthYearData, r, o);
      });
      dataIntegrityCheck['to_be_paid'] = {
        transactions:[]
      };
      props.wallet.to_be_paid.forEach((result: any) => {
        dataIntegrityCheck['to_be_paid'].transactions.push(result.amount)
        sumOfHistoryPoints += result.amount
        result.category = 'to_be_paid'
        summateAndCategorize(result, categories, monthYearData, r, o);
      });
      dataIntegrityCheck['pending'] = {
        transactions:[]
      };
      props.wallet.pending.forEach((result: any) => {
        dataIntegrityCheck['pending'].transactions.push(result.amount)
        sumOfHistoryPoints += result.amount
        result.category = 'pending'
        summateAndCategorize(result, categories, monthYearData, r, o);
      });
      dataIntegrityCheck['validated'] = {
        transactions:[]
      };
      props.wallet.validated.forEach((result: any) => {
        dataIntegrityCheck['validated'].transactions.push(result.amount)
        sumOfHistoryPoints += result.amount
        result.category = 'validated'
        summateAndCategorize(result, categories, monthYearData, r, o);
      });
      dataIntegrityCheck['refused'] = {
        transactions:[]
      };
      props.wallet.refused.forEach((result: any) => {
        dataIntegrityCheck['refused'].transactions.push(result.amount)
        sumOfHistoryPoints += result.amount
        result.category = 'refused'
        summateAndCategorize(result, categories, monthYearData, r, o);
      });
      // console.log(sumOfHistoryPoints, categories, r, dataIntegrityCheck)
      // const entries = Object.keys(categories).map(key => ({
      //       name: key,
      //       value: categories[key].points
      // } ))
      // const objectArray = Object.entries(categories);
      // let cats:any = [];
      // objectArray.forEach(([key, value]) => {
      //   // console.log(key); // 'one'
      //   // console.log(value); // 1
      //   cats.push({
      //     name: key,
      //     points: categories[key].points,
      //     value: percentage(categories[key].points, sumOfHistoryPoints)
      //   })
      // });

      // console.log(entries.map((key)=> {
      //   {key: categories[key].points}
      // }))
      // console.log(r)

      setSumOfHistoryPoints(sumOfHistoryPoints);
      r.sort(function(a:any,b:any){
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        return new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime();
      });
      // setcategoriesDistribution(cats)
      setMonthlyDistribution(r)
    }

  }, [props.wallet]);

  // let percentage = (partialValue:number, totalValue:number) => {
  //   return Math.round((100 * partialValue) / totalValue);
  // }
  const refetchWallet = (start: string, end: string) => {
    setTimeout(() => {
      props.getWallet(start, end);
    }, 500);
  };
  // const [showModal, setShowModal] = useState(false);

  return (
    <IonPage className="pt-14">
      <IonContent className="bg-gray-400 relative h-full">
        <div className="bg-gray-100">
          <HolderCard />
        </div>
        <DateFilter refetchWallet={refetchWallet} />
        <TotalSavings sumOfHistoryPoints={sumOfHistoryPoints} />
        {/* <div className="mx-6 mb-12 border-t"></div> */}
        {/* <SavingsByCategory categories={categoriesDistribution} sumOfHistoryPoints={sumOfHistoryPoints} /> */}
        {/* <div className="mx-6 mb-8 border-t"></div> */}
        {/*<PointsBarChart monthlyData={monthlyDistribution} />*/}
        <PointsList monthlyData={monthlyDistribution}  />

      </IonContent>
    </IonPage>
  );
};


export const Points = connect(
    mapStateToProps,
    mapDispatchToProps
)(PointsComponent);

// lassName="pt-4 pb-1 m-4 mt-6 rounded-md shadow-custom">
//  className="pb-6 pl-6">Total points exchanged:</div>
//  className="mb-6 h-44">
// esponsiveContainer width="100%" height="100%">
// <AreaChart
//   stackOffset="expand"
//   margin={{ right: 30, left: -20, top: 0, bottom: 0 }}
//   data={data2}
// >
//   <XAxis
//     dataKey="name"
//     interval={0}
//     tick={<CustomXAxisTick />}
//     tickLine={{ stroke: "white" }}
//     axisLine={{ stroke: "#eeeeee" }}
//   />
//   <YAxis
//     interval={0}
//     tick={<CustomYAxisTick />}
//     tickLine={{ stroke: "white" }}
//     axisLine={{ stroke: "white" }}
//   />
//   <Tooltip />
//   <Area
//     type="monotone"
//     dataKey="points"
//     stroke="#014971"
//     fill="#E5F4EF"
//   />
// </AreaChart>
// ResponsiveContainer>
// v>
//

//  <RoundTabs
//   tabs={tabs.map((t) => t.name)}
//   selected={tabs.filter((t) => t.current).map((t) => t.name)[0]}
//   onSelect={(tab) => {
//     setTabs(
//       tabs.map((tabitem) => ({
//         ...tabitem,
//         current: tabitem.name === tab,
//       }))
//     );
//   }}
// />
