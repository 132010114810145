import { IonContent, IonPage } from "@ionic/react";
import './invalid-card-number.scss';
import { CreditCardIcon } from "@heroicons/react/outline";
import sadface from "./sadface.png";
export const InvalidCardNumber = () => {
    return (
        <IonPage className="pt-16 dark:bg-white">
            <IonContent>
                <div className="invalid-card-number items-center dark:text-black">
                    <img className="flex h-24" src={sadface} />
                    <div className="text-center text-lg px-6 pt-4">予期せぬアクセスです。</div>
                    <div className="text-center text-lg px-6 pb-4">
                        前の画面にお戻りください。
                    </div>
                </div>
            </IonContent>
        </IonPage>
    );
};
