import {AppSettings} from "../AppSettings";

const GTMService = {
    triggerGTMLogin: (storesList: any, starStore: any, cardNo: any) => {
        let userData: any = localStorage.getItem("user-data")
        if (userData) userData = JSON.parse(userData);
        let gtmObject = {
            dm_store_list: storesList,
            dm_store_number: starStore ? starStore.id : 0,
            event: "virtualPageview",
            pageTitle: "login",
            pageUrl: window.location.pathname, // replace page url with current url
            dm_screen_type: "other", // if list of coupons displayed then "list_view" , if one coupon displayed as product then "product_view" otherwise set to "other"})
            dm_okuwa_ref: cardNo.current,
            dm_ref: cardNo.current,
            dm_signup_email: cardNo.current + AppSettings.CATALINA_DOMAIN,
            dm_user_id: userData ? userData.id : 0
        }

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(gtmObject);
        console.log(gtmObject);
    },

    triggerGTMSignup: (cardNo: any) => {
        window.dataLayer = window.dataLayer || [];
        let gtmObject = {
            event: "virtualPageview",
            pageTitle: "signup",
            pageUrl: window.location.pathname, // replace page url with current url
            dm_screen_type: "other", // if list of coupons displayed then "list_view" , if one coupon displayed as product then "product_view" otherwise set to "other"})
            dm_okuwa_ref: cardNo.current,
            dm_ref: cardNo.current,
            dm_signup_email: cardNo.current + AppSettings.CATALINA_DOMAIN
        }
        window.dataLayer.push(gtmObject);
        console.log(gtmObject);
    },
    triggerGTM: (data: any) => {
        let userData: any = localStorage.getItem("user-data")
        if (userData) userData = JSON.parse(userData);
        const eventProps = data.data;
        let cardNo = eventProps.cards && eventProps.cards.holders && eventProps.cards.holders.length > 0 ? eventProps.cards.holders[0].ref : 0
        let favoriteStores = data.eventName === "storeRegistered" ? eventProps.stores.favoriteStores : "";
        let storeIds = favoriteStores.length > 0 ? favoriteStores
            .map((b: { id: any }) => b.id) : [0]
        let dm_category_id = eventProps.categories.selectedCategory
            ? eventProps.categories.selectedCategory.id
            : 0;
        let pageUrl = null;
        let screenType = "other";
        let dm_offer_id = eventProps.offerDetails.selectedOffer.id
        if (data.eventName === 'NAVIGATE') {
            pageUrl = data.url
            data.eventName = data.url.replace(/\//g,'');
        }
        if (data.eventName === 'REMOVED') {
            data.eventName = "offer_removed"
            screenType = 'offer_removed'
        }
        if (data.eventName === 'points') {
            screenType = 'Account'
        } else if (data.eventName === 'info') {
            screenType = 'Information'
        } else if (data.eventName === "storeRegistered") {
            storeIds.push(eventProps.selectedStore.id)
        } else if (data.eventName === "home" || data.eventName === "Clipped" || data.eventName === "cart") {
            screenType = "list_view";
        } else if (data.eventName === "ClippedFromDetails") {
            screenType = "product_view";
            pageUrl = '/product-details'
            data.eventName = "Clipped"
        } else if (data.eventName === "CATEGORY_CHANGED") {
            screenType = "list_view";
            dm_category_id = eventProps.changedCategory ? eventProps.changedCategory.id : 0;
        } else if (data.eventName === "product_view") {
            screenType = "product_view";
            dm_offer_id = data.product.id;
        }

        window.dataLayer = window.dataLayer || [];
        let gtmObject = {
            event: 'virtualPageview',
            pageUrl: pageUrl || window.location.pathname,
            pageTitle: data.eventName,
            dm_offer_id,
            dm_offers: eventProps.offerList.offers
                ? eventProps.offerList.offers.map((b: { id: any }) => b.id).join("_")
                : 0, // fill dynamic uisng api value when page/state changes,
            dm_reward_list: eventProps.offerList.basket
                ? eventProps.offerList.basket.map((b: { id: any }) => b.id).join("_")
                : 0,
            dm_basket_list: eventProps.offerList.basket
                ? eventProps.offerList.basket.map((b: any) => b.offer.id).join("_")
                : 0,
            dm_store_list: eventProps.stores.selectedStore
                ? eventProps.stores.favoriteStores
                    .map((b: { id: any }) => b.id)
                    .join("_")
                : 0,
            dm_store_number: eventProps.stores.selectedStore
                ? eventProps.stores.selectedStore.id
                : 0, // fill dynamic uisng api value when page/state changes
            dm_screen_type: screenType, //fill dynamic:'sign up' 'dm_screen_type': 'list_view' for list view screen, 'product_view' for products view screen
            dm_okuwa_ref: eventProps.cards.holders
                ? eventProps.cards.holders.map((b: { ref: any }) => b.ref).join("_")
                : 0,
            dm_ref: eventProps.cards.holders
                ? eventProps.cards.holders.map((b: { ref: any }) => b.ref).join("_")
                : 0,
            dm_okuwa_stores: eventProps.stores.selectedStore
                ? eventProps.stores.favoriteStores
                    .map((b: { id: any }) => b.id)
                    .join("_")
                : 0,
            dm_signup_email: cardNo + AppSettings.CATALINA_DOMAIN,                                //cwallet backend signup email
            dm_user_id: JSON.parse(localStorage.getItem("user-data")!).id, //fill dynamic uisng api value when page/state changes
            dm_retailer_id: eventProps.stores.selectedStore
                ? eventProps.stores.selectedStore.retailer.id
                : 0, //fill dynamic uisng api value when page/state changes
            dm_category_id: dm_category_id, //fill dynamic uisng api value when page/state changes
            dm_basket_selected_offer: eventProps.offerDetails.selectedOffer.id,
        }
        console.log(gtmObject)
        window.dataLayer.push(gtmObject);
    }
};

export default GTMService;
