import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { useTranslation } from "react-i18next";
import { classNames } from "../../pages/header/header";
import {MapWithAMarker} from "../google-map/google-map";

interface Props {
  store: any;
  onLeftClick: Function;
  onRightClick: Function;
  onCancel: Function;
  leftText: string;
  rightText: string;
  title: string;
  isOpen: boolean;
  close: Function;
  mode: string; // ADD or DELETE
  mainButton: string; // LEFT or RIGHT
  hideLeftButton?: boolean;
}

export const StoreAddOrDeleteModal = (props: Props) => {
  const cancelButtonRef = useRef(null);
  const { t } = useTranslation();

  return (
    <Transition.Root show={props.isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto px-4"
        initialFocus={cancelButtonRef}
        onClose={() => props.close()}
      >
        <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-opacity-75 transition-opacity bg-gray-700" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all w-full">
              <div className="max-w-sm w-full bg-white pointer-events-auto">
                <div className="p-4">
                  <div className="flex items-start">
                    <div className="flex-shrink-0 pt-0.5">
                      <img
                          className="h-10 w-10 rounded-full"
                          src={props.store.retailer.picture_url}
                          alt=""
                      />
                    </div>
                    <div className="ml-3 w-0 flex-1">
                      <p className="text-sm font-medium text-gray-900">{props.store.name}</p>
                      <p className="mt-1 text-sm text-gray-500">{props.title}</p>
                    </div>
                    <div className="ml-4 flex-shrink-0 flex">
                      <button
                          type="button"
                          onClick={() => props.close()}
                          className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"

                      >
                        <span className="sr-only">Close</span>
                        <XIcon className="h-5 w-5" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {/*<div className="flex px-6 py-4 items-center">*/}
              {/*  <div className="flex-1 text-left text-lg font-bold dark:text-black">*/}
              {/*    {props.title}*/}
              {/*  </div>*/}
              {/*  <div onClick={() => props.close()}>*/}
              {/*    <XIcon className="w-4" />*/}
              {/*  </div>*/}
              {/*</div>*/}
              {/*<MapWithAMarker*/}
              {/*  lat={props.store.lat}*/}
              {/*  lng={props.store.lng}*/}
              {/*  googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCd-dtFFPZ7uArahFfTzx2WfMfLLY7hVVI&v=3.exp&libraries=geometry,drawing,places"*/}
              {/*  loadingElement={<div style={{ height: `100%` }} />}*/}
              {/*  containerElement={<div style={{ height: `300px` }} />}*/}
              {/*  mapElement={<div style={{ height: `100%` }} />}*/}
              {/*/>*/}
              {/*<div className="py-2 px-6">*/}
              {/*  <div className="text-left text-lg  dark:text-black">{props.store.name}</div>*/}
              {/*</div>*/}
              <div className="grid grid-cols-2 gap-4 px-4 pb-4">
                {!props.hideLeftButton && (
                  <button
                    type="button"
                    className={classNames(
                      props.mainButton === "LEFT"
                        ? "bg-okuwa_blue text-white"
                        : "bg-okuwa_bg text-gray-800",
                      "justify-center py-3 font-medium rounded-xl"
                    )}
                    onClick={() => props.onLeftClick()}
                  >
                    {t(props.leftText)}
                  </button>
                )}
                <button
                  type="button"
                  className={classNames(
                    props.mainButton === "RIGHT"
                      ? "bg-okuwa_blue text-white"
                      : "bg-okuwa_bg text-gray-800",
                    props.hideLeftButton ? "col-span-2" : "",
                    "justify-center py-3 font-medium rounded-xl"
                  )}
                  onClick={() => props.onRightClick()}
                  ref={cancelButtonRef}
                >
                  {t(props.rightText)}
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
