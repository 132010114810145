import { SET_WALLET, SET_START_DATE, SET_END_DATE } from "./wallet.types";

import {SET_RETAILER_LIST} from "../retailers/retailers.types";
import DataService from "../../services/data.service";
import MembersService from "../../services/members.service";

// MembersService.getHolders()
//     .then((responseJson) => {
//       DataService.holders.set(responseJson.data);
//       if (responseJson.data.length) {
//         setHolder(responseJson.data[0]);
//       }
//     })
//     .catch((error) => {
//       console.log(error);
//     });

export const fetchRetailers = () => {
  // console.log("fetchRetailers");
  return dispatch => {
    // console.log("fetchRetailers dispatch");
    DataService.getRetailers()
        .then(res => {
          const retailers = res.data;
          dispatch({
            type: SET_RETAILER_LIST,
            payload: retailers
          });
        })
  };
};
export const setMemberWallet = (data) => {
  return {
    type: SET_WALLET,
    payload: data,
  };
};

export const setWalletStartDate = (data) => {
  return {
    type: SET_START_DATE,
    payload: data,
  };  
}

export const setWalletEndDate = (data) => {
  return {
    type: SET_END_DATE,
    payload: data,
  };  
}

export const getWallet = (startDate, endDate) => {
  // console.log("getWallet");
  return dispatch => {
    // console.log("getWallet dispatch");
    MembersService.getWallet(startDate, endDate)
        .then(res => {
          const wallet = res.data;
          dispatch({
            type: SET_WALLET,
            payload: wallet
          });
        })
  };
};
