import {Redirect, Route} from "react-router-dom";
import {IonApp, IonRouterOutlet, setupIonicReact} from "@ionic/react";
import {IonReactRouter} from "@ionic/react-router";
import TagManager from 'react-gtm-module'

import "./App.scss";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import {Provider} from "react-redux";

import store from "./redux/store";
import {TermsPage} from "./pages/terms/terms";
import {Terms2Page} from "./pages/terms2/terms2";
import {Home} from "./pages/home/home";

import i18n from "./i18n";
import {Suspense, useEffect, useState} from "react";
import LocaleContext from "./LocaleContext";
import {changeLanguage} from "i18next";
import {Header} from "./pages/header/header";
import {Points} from "./pages/points/points";
import {PagesMenu} from "./pages/pages/pages";
import {ProductDetailsPage} from "./pages/product-details/product-details";
import {Cart} from "./pages/cart/Cart";
import { AccountScreen } from "./pages/account/account";
import {InvalidCardNumber} from "./pages/invalid-card-number/invalid-card-number";
import { Onboarding } from "./pages/onboarding/onboarding";
import {ManageStore} from "./pages/manage-stores/manage-stores";
import { Info } from "./pages/info/info";
import { HowToUseApp } from "./pages/info/infoscreens/how-to-use";
import { Faq } from "./pages/info/infoscreens/faq";
import { Inquiry } from "./pages/info/infoscreens/inquiry/inquiry";
import { TermsOfService } from "./pages/info/infoscreens/terms-service";
import { Privacy } from "./pages/info/infoscreens/privacy";
import {ErrorPage} from "./pages/error/error";

setupIonicReact();

i18n.init({
  debug: false,
});
const REACT_APP_GTM_ID = process.env.REACT_APP_GTM_ID as string;

const tagManagerArgs = {
  gtmId: REACT_APP_GTM_ID
}
TagManager.initialize(tagManagerArgs)

const App: React.FC = () => {
  const [locale, setLocale] = useState(i18n.language);
  const setLanguage = (language: string) => {
    setLocale(language);
  };
  i18n.on("languageChanged", (lng) => setLocale(i18n.language));

  useEffect(() => {
    i18n.changeLanguage("jp");
  }, []);
  return (
    <LocaleContext.Provider value={{locale, setLanguage}}>
      <Suspense fallback={<div>Loading</div>}>
        <Provider store={store}>
          <IonApp>
            <IonReactRouter>
              <Header/>
              <IonRouterOutlet mode="ios" id="main">
                <Route exact path="/terms">
                  <TermsPage/>
                </Route>
                <Route exact path="/terms2">
                  <Terms2Page/>
                </Route>
                <Route exact path="/invalid-card-number">
                  <InvalidCardNumber />
                </Route>
                <Redirect to="/invalid-card-number" />
                <Route exact path="/pages">
                  <PagesMenu/>
                </Route>
                <Route exact path="/home">
                  <Home/>
                </Route>
                <Route exact path="/stores">
                  <ManageStore/>
                </Route>
                <Route exact path="/product-details/:id">
                  <ProductDetailsPage/>
                </Route>
                <Route exact path="/cart">
                  <Cart/>
                </Route>
                <Route exact path="/error">
                  <ErrorPage />
                </Route>
                <Route exact path="/">
                  <Onboarding/>
                </Route>
                <Route exact path="/points">
                  <Points/>
                </Route>
                <Route exact path="/account">
                  <AccountScreen />
                </Route>
                <Route exact path="/info/how-to-use-app">
                  <HowToUseApp />
                </Route>
                <Route exact path="/info/faq">
                  <Faq />
                </Route>
                <Route exact path="/info/inquiry">
                  <Inquiry />
                </Route>
                <Route exact path="/info/terms-service">
                  <TermsOfService />
                </Route>
                <Route exact path="/info/privacy">
                  <Privacy />
                </Route>
                <Route exact path="/info">
                  <Info />
                </Route>
                
              </IonRouterOutlet>
            </IonReactRouter>
          </IonApp>
        </Provider>
      </Suspense>
    </LocaleContext.Provider>
  );
};

export default App;
